import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext,
    createContext
} from 'react';
import PropTypes from 'prop-types';
import { useError } from 'components/ErrorContext/ErrorContext';
import { getChatbotConfiguration, getChatbotGreeting } from 'api/configuration';
import { DEFAULT_CHAT_TYPE, CHAT_TYPE } from 'constants/Chat';

export const ConfigContext = createContext({});

export const useConfig = () => {
    return useContext(ConfigContext);
};

const requiredConfigurationKeys = [
    'primary_color',
    'logo',
    'name',
    'employee_group_id',
    'chat_type',
    'disable_live_chat',
];

export function ConfigProvider ({ children, apiKey, communityId }) {
    const [configurationObtained, setConfigurationObtained] = useState(false);
    const [configValues, setConfigValues] = useState({});

    const { error, handleError } = useError();

    const fetchData = useCallback(async () => {
        try {
            const response = await getChatbotConfiguration(communityId, apiKey);
            const missingKeys = requiredConfigurationKeys.filter(key => !Object.keys(response).includes(key));
            if (missingKeys.length) {
                handleError(new Error(`Chat configuration error. The following keys are missing: ${missingKeys.join(', ')}.`));
            }

            const values = {
                color: response.primary_color,
                logoUrl: response.logo,
                avatarUrl: response.avatar,
                name: response.name,
                employeeGroupId: response.employee_group_id,
                chatType: response.chat_type || DEFAULT_CHAT_TYPE,
                proactiveMessage: response.proactive_message,
                disableLiveChat: response.disable_live_chat,
                brandingEnabled: response.branding_enabled,
                brandingLogoUrl: response.branding_logo,
                resmanBrandingUrl: response.branding_url,
                residentRedirectUrl: response.resident_redirect_url,
                introMessage: response.intro_message,
                messageDelayMs: null,
                greetingButtons: null,
            };

            setConfigValues(values);
            setConfigurationObtained(true);
        } catch (e) {
            handleError(e);
        }
    }, [apiKey, communityId, handleError]);

    const hideChatbot = useMemo(() => {
        if (!configurationObtained || !configValues) {
            return true;
        }

        if (configValues.disableLiveChat && configValues.chatType === CHAT_TYPE.LIVE_ONLY) {
            return true;
        }

        return false;
    }, [
        configurationObtained,
        configValues
    ]);

    useEffect(() => {
        fetchData();
    }, [
        fetchData
    ]);

    const contextValue = useMemo(() => ({
        configurationObtained,
        configValues,
        error
    }), [
        configurationObtained,
        configValues,
        error
    ]);

    if (hideChatbot) {
        return null;
    }

    return(
        <ConfigContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ConfigContext.Provider>
    );
}

ConfigProvider.propTypes = {
    children: PropTypes.any.isRequired,
    apiKey: PropTypes.string.isRequired,
    communityId: PropTypes.number.isRequired
};
