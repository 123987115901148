const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const toPrice = (value) => {
  return priceFormatter.format(value);
};

export const toSquareFoot = (value) => {
  return `${value} sq. ft.`;
};

export const toQuery = obj => {
  const entries = Object.entries(obj);
  return entries.reduce((qs, [k, v]) => qs.concat(k + '=' + v), []).join('&');
};
