import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext,
    createContext
} from 'react';
import PropTypes from 'prop-types';
import { useConfig } from 'components/Config/ConfigContext';
import { POP_HAND_RAISE_DELAY, useChat } from '../Chat/ChatContext';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';
import { EVENT_TYPE } from 'constants/Chat';

export const StartChatButtonContext = createContext({});

export const useStartChatButton = () => {
    return useContext(StartChatButtonContext);
};

export function StartChatButtonProvider({ children }) {
    const openPopupMessageTimeoutId = useRef();
    const [isPopupMessageVisible, setIsPopupMessageVisible] = useState(false);
    const { openChat, sessionId, funnelData } = useChat();
    const { configValues: { proactiveMessage }  } = useConfig();

    // Use effect to show popup message after POP_HAND_RAISE_DELAY
    useEffect(() => {
        if (!proactiveMessage || proactiveMessage === '') return;

        openPopupMessageTimeoutId.current = setTimeout(() => {
            setIsPopupMessageVisible(true);
        }, POP_HAND_RAISE_DELAY);

        return () => {
            clearTimeout(openPopupMessageTimeoutId.current);
        };
    }, [
        proactiveMessage
    ]);

    // Use effect to hide popup message once chat has been opened
    useEffect(() => {
        if (openChat) {
            setIsPopupMessageVisible(false);
        }
    }, [
        openChat
    ]);

    const handleOpenChat = useCallback(() => {
        openChat();
    }, [
        openChat
    ]);

    const handleClosePopupMessage = useCallback((event) => {
        event.stopPropagation();
        setIsPopupMessageVisible(false);
        trackChatbotEvent(sessionId, EVENT_TYPE.CLOSE_WELCOME_MESSAGE, funnelData);
    }, [funnelData, sessionId]);

    const contextValue = useMemo(() => ({
        isPopupMessageVisible,
        handleOpenChat,
        handleClosePopupMessage
    }), [
        isPopupMessageVisible,
        handleOpenChat,
        handleClosePopupMessage
    ]);

    return (
       <StartChatButtonContext.Provider value={contextValue}>
           {typeof children === 'function' ? children(contextValue) : children}
       </StartChatButtonContext.Provider>
    );
}

StartChatButtonProvider.propTypes = {
    children: PropTypes.any.isRequired
};
