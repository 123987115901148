import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import ArrowDown from 'components/Svg/ArrowDownSvg';
import styles from './MessageShowMore.scss';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';
import { EVENT_TYPE } from 'constants/Chat';

function MessageShowMore ({
    more,
    moreLabel,
    scrollToBottom,
    sessionId,
    funnelData,
}) {
    const compRef = useRef();
    const [showMore, setShowMore] = useState(false);
    const handleExpandClick = useCallback(() => {
        setShowMore(true);
        scrollToBottom();
        trackChatbotEvent(sessionId, EVENT_TYPE.MESSAGE_SHOW_MORE_CLICK, funnelData);
    }, [funnelData, scrollToBottom, sessionId]);

    return (
        <div
            ref={compRef}
            className={styles.component}
        >
            {!showMore ? (
                <Button
                    data-testid="message-more-show-more-button"
                    variant="clear"
                    onClick={handleExpandClick}
                >
                    {moreLabel}
                    <ArrowDown />
                </Button>
            ) : (
                <span data-testid="message-more-more-text">{more}</span>
            )}
        </div>
    );
}

MessageShowMore.propTypes = {
    more: PropTypes.string.isRequired,
    moreLabel: PropTypes.string.isRequired,
    scrollToBottom: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    funnelData: PropTypes.object.isRequired,
};

MessageShowMore.defaultProps = {
    moreLabel: 'Read more'
};

export default MessageShowMore;
