import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import { SENTRY_DSN, SENTRY_AUTH_TOKEN } from 'config';

const shouldUseSentry = !!SENTRY_AUTH_TOKEN;

export const setupSentry = () => {
    if (!shouldUseSentry) return;

    Sentry.init({
        dsn: SENTRY_DSN,
        defaultIntegrations: false,
        release: `nestio-chatbot-client@${RELEASE}`, // equal to package.json name
        integrations: [new RewriteFrames()],
    });
};

export const logToSentry = (error) => {
    if (!shouldUseSentry) return;

    Sentry.captureException(error);
};

