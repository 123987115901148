import React, {
    useRef,
    useState,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Slider from 'react-slick';
import {
    unitType,
    unitsTypeType
} from 'types';
import useAutoFocus from 'hooks/useAutoFocus';
import Unit from 'components/Unit/Unit';
import ArrowRightSvg from 'components/Svg/ArrowRightSvg';
import ArrowLeftSvg from 'components/Svg/ArrowLeftSvg';
import styles from './Units.scss';

const settings = {
    dots: false,
    infinite: false,
    adaptiveHeight: true,
    arrows: false
};

function Units ({ units, unitsType }) {
    const unitsContainer = useRef(null);

    useAutoFocus(unitsContainer);

    const sliderRef = useRef();
    const [activeUnit, setActiveUnit] = useState(0);
    const showNextArrow = units?.length > 1 && activeUnit < units?.length -1;
    const showPreviousArrow = units?.length > 1 && activeUnit > 0;

    const handlePreviousClick = useCallback(() => {
        if (!sliderRef.current) return;

        sliderRef.current.slickPrev();
    }, []);

    const handleNextClick = useCallback(() => {
        if (!sliderRef.current) return;

        sliderRef.current.slickNext();
    }, []);

    const handleAfterSliderChange = useCallback((index) => {
        setActiveUnit(index);
    }, []);

    return (
        <div
            data-testid="units"
            ref={unitsContainer}
            className={styles.component}
            tabIndex="0"
        >
            <div
                data-testid="previous-unit-arrow"
                className={cx(styles.previousArrow, {
                    [styles.isActive]: showPreviousArrow
                })}
                onClick={handlePreviousClick}
            >
                <ArrowLeftSvg />
            </div>

            <Slider
                ref={sliderRef}
                afterChange={handleAfterSliderChange}
                {...settings}
            >
                {units.map((unit, index) => {
                    return (
                        <Unit
                            data-testid={`unit-${index}`}
                            key={index}
                            unit={unit}
                            type={unitsType}
                        />
                    );
                })}
            </Slider>
            <div
                data-testid="next-unit-arrow"
                className={cx(styles.nextArrow, {
                    [styles.isActive]: showNextArrow
                })}
                onClick={handleNextClick}
            >
                <ArrowRightSvg />
            </div>
        </div>
    );
}

Units.propTypes = {
    units: PropTypes.arrayOf(unitType).isRequired,
    unitsType: unitsTypeType.isRequired
};

Units.defaultProps = {
    units: []
};

export default Units;
