import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    cloneElement
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useParentWindow } from 'components/ParentWindow/ParentWindowContext';
import styles from './Tooltip.scss';

const debouncer = debounce(
    f => f(),
    300,
    { leading: true }
);

function Tooltip({
    children,
    title,
    position,
    leftOffset = 0,
    topOffset = 0
}) {
    const { isDesktop } = useParentWindow();
    const childrenRef = useRef();
    const tooltipRef = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState({
        top: 0,
        left: 0,
        visibility: 'hidden'
    });

    const toggleVisibility = useCallback(() => {
        setIsVisible(!isVisible);
    }, [
        isVisible
    ]);

    const clonedChildren = cloneElement(children, {
        ...children.props,
        ref: childrenRef,
        onMouseEnter: toggleVisibility,
        onMouseLeave: toggleVisibility
    });

    const setTooltipToTop = useCallback((childrenDomRect, tooltipDomRect) => {
        setTooltipStyle({
            top: childrenDomRect.top - tooltipDomRect.height - topOffset,
            left: childrenDomRect.left - (tooltipDomRect.width / 2) + leftOffset,
            visibility: 'visible'
        });
    }, [
        topOffset,
        leftOffset
    ]);

    const setTooltipToBottom = useCallback((childrenDomRect, tooltipDomRect) => {
        setTooltipStyle({
            top: childrenDomRect.top + tooltipDomRect.height + topOffset,
            left: childrenDomRect.left - (tooltipDomRect.width / 2) + leftOffset,
            visibility: 'visible'
        });
    }, [
        topOffset,
        leftOffset
    ]);

    const determineTooltipPosition = useCallback(() => {
        if (!isDesktop) {
            return setTooltipStyle({
                ...setTooltipStyle,
                visibility: 'hidden'
            });
        }

        if (!isVisible) {
            return;
        }

        const childrenDomRect = childrenRef.current.getBoundingClientRect();
        const tooltipDomRect = tooltipRef.current.getBoundingClientRect();
        if (position === 'top') {
            setTooltipToTop(childrenDomRect, tooltipDomRect);
        } else if (position === 'bottom') {
            setTooltipToBottom(childrenDomRect, tooltipDomRect);
        }
    }, [
        isDesktop,
        position,
        isVisible,
        setTooltipToTop,
        setTooltipToBottom
    ]);

    const windowResizeHandler = useCallback(() => debouncer(() => {
        determineTooltipPosition();
    }), [
        determineTooltipPosition
    ]);

    useEffect(() => {
        window.addEventListener('resize', windowResizeHandler);

        return () => {
            window.removeEventListener('resize', windowResizeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!childrenRef.current || !tooltipRef.current) {
            return;
        }

        determineTooltipPosition();
    }, [
        childrenRef,
        tooltipRef,
        determineTooltipPosition
    ]);

    return (
        <div>
            {clonedChildren}
            <div className={styles.component}>
                {isVisible && (
                    <span
                        data-testid="tooltip"
                        ref={tooltipRef}
                        className={styles.tooltip}
                        style={tooltipStyle}
                    >
                        {title}
                    </span>
                )}
            </div>
        </div>
    );
}

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    position: PropTypes.oneOf([
        'top',
        'bottom'
    ]).isRequired,
    topOffset: PropTypes.number,
    leftOffset: PropTypes.number,
};

export default Tooltip;
