import React, {
    useEffect,
    useCallback,
    useRef
} from 'react';
import cx from 'classnames';
import { CHAT_STATE, CHAT_TYPE } from 'constants/Chat';
import { isTest } from 'utils/env';
import usePrevious from 'hooks/usePrevious';
import { useChat } from 'components/Chat/ChatContext';
import { useParentWindowCommunicator } from 'components/ParentWindowCommunicator/ParentWindowCommunicator';
import { useConfig } from 'components/Config/ConfigContext';
import { useError } from 'components/ErrorContext/ErrorContext';
import LiveChatForm from 'components/LiveChatForm/LiveChatForm';
import ChatBotInformationForm from 'components/ChatBotInformationForm/ChatBotInformationForm';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatFooter from 'components/ChatFooter/ChatFooter';
import ChatMessageForm from 'components/ChatMessageForm/ChatMessageForm';
import ChatMessages from 'components/ChatMessages/ChatMessages';
import styles from './Chat.scss';

function Chat () {
    const {
        messages,
        awaitingResponse,
        isOpen,
        closeChat,
        sendMessage,
        initializeLiveChat,
        submitChatbotInformationForm,
        chatState,
        chatMode,
        showLiveChatForm,
        setShowLiveChatForm,
        showChatBotInformationForm,
        setShowChatBotInformationForm,
        handleRefreshChat
    } = useChat();

    const {
        error
    } = useError();

    const { configValues: {
        chatType,
        brandingEnabled,
        brandingLogoUrl,
        resmanBrandingUrl,
    } } = useConfig();

    const prevAwaitingResponse = usePrevious(awaitingResponse);
    const { isDesktop } = useParentWindowCommunicator();
    const { configValues } = useConfig();
    const isFullScreen = !isDesktop && isOpen;
    const lastMessage = messages?.length > 0 ? messages[messages.length - 1] : null;
    const disableMessageInput = lastMessage?.payload?.disable_message_input;
    const messageInputIsEnabled = !disableMessageInput && chatState === CHAT_STATE.CONNECTED && !error;

    const messagesListRef = useRef(null);

    const scrollToBottom = useCallback(() => {
        if (error) return;
        if (!messagesListRef) return;
        // Without the next line the test will fail while the app works as expected...
        if (isTest && (!messagesListRef?.current?.scrollTop || !messagesListRef?.current?.scrollHeight)) return;

        // Deplay is required because otherwise we perform a scroll before the padding has been calculated
        // See useChatPaddingBottom hook
        const scrollTimeoutId = setTimeout(() => {
            if (messagesListRef.current) {
                messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
            }
        }, 10);

        return () => {
            clearTimeout(scrollTimeoutId);
        };
    }, [
        messagesListRef,
        error
    ]);

    useEffect(() => {
        if (!messages?.length) {
            return;
        }
        scrollToBottom();
    }, [
        messages,
        scrollToBottom
    ]);

    useEffect(() => {
        const awaitingResponseBecameTrue = prevAwaitingResponse !== awaitingResponse && awaitingResponse;
        if (awaitingResponseBecameTrue) {
            scrollToBottom();
        }
    }, [
        awaitingResponse,
        prevAwaitingResponse,
        scrollToBottom
    ]);

    const handleCloseChat = useCallback(async () => {
        closeChat();
    }, [
        closeChat
    ]);

    const handleSubmit = useCallback((values) => {
        if (!values?.message) {
            return;
        }

        sendMessage(values.message, {});
    }, [
        sendMessage
    ]);

    const closeLiveChatForm = useCallback(() => {
        setShowLiveChatForm(false);
        scrollToBottom();
    }, [
        setShowLiveChatForm,
        scrollToBottom
    ]);

    const closeChatBotInformationForm = useCallback(() => {
        setShowChatBotInformationForm(false);
        scrollToBottom();
    }, [
        setShowChatBotInformationForm,
        scrollToBottom
    ]);


    return (
        <div
            role="region"
            aria-label="Chat window"
            data-testid="chat"
            className={cx(styles.component, {
                [styles.fullScreen]: isFullScreen
            })}
        >
            <h1 className="sr-only">Chat window</h1>
            <ChatHeader
                configValues={configValues}
                handleRefreshChat={handleRefreshChat}
                handleCloseChat={handleCloseChat}
            />
            {showLiveChatForm && (
                <LiveChatForm
                    initializeLiveChat={initializeLiveChat}
                    displayCloseButton={chatType !== CHAT_TYPE.LIVE_ONLY}
                    closeForm={closeLiveChatForm}
                />
            )}
            {showChatBotInformationForm && (
                <ChatBotInformationForm
                    submitChatbotInformationForm={submitChatbotInformationForm}
                    displayCloseButton={true}
                    closeForm={closeChatBotInformationForm}
                    requestType={showChatBotInformationForm}
                />
            )}
            {(!showLiveChatForm && !showChatBotInformationForm) && (
                    <>
                        {error ? (
                            <div
                                data-testid="chat-error"
                                className={styles.error}
                            >
                                <div className={styles.errorHeader}>
                                    Oops
                            </div>
                                <div className={styles.errorBody}>
                                    We are experiencing technical difficulties.<br/>
                                Try refreshing the chat by clicking <a onClick={handleRefreshChat}>here</a>
                                </div>
                                <div className={styles.errorImage} />
                            </div>
                        ) : (
                                <ChatMessages
                                    awaitingResponse={awaitingResponse}
                                    messages={messages}
                                    reference={messagesListRef}
                                    scrollToBottom={scrollToBottom}
                                    chatMode={chatMode}
                                    configValues={configValues}
                                />
                            )}
                        <div className={`${styles.footer} ${!brandingEnabled && styles.noBranding}`}>
                            <ChatMessageForm
                                isEnabled={messageInputIsEnabled}
                                handleSubmit={handleSubmit}
                            />
                            <ChatFooter
                                brandingEnabled={brandingEnabled}
                                brandingLogoUrl={brandingLogoUrl}
                                resmanBrandingUrl={resmanBrandingUrl}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default Chat;
