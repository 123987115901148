import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    CHAT_MODE,
    CHAT_SESSION_ID_STORAGE_NAME_SPACE,
    CHAT_STORAGE_CHAT_NAME_SPACE,
    MESSAGES_STORAGE_NAME_SPACE,
    STORAGE_EXPIRY
} from 'constants/Chat';
import * as storageUtils from 'utils/storage';

export const ChatStorageContext = createContext({});

export const useChatStorage = () => {
    return useContext(ChatStorageContext);
};

let storageType = null;

export function ChatStorageProvider({ communityId, children }) {
    const setStorageType = useCallback((type) => {
        storageType = type;
    }, []);

    const getChatNamespace = useCallback((mode) => {
        mode = mode || storageType;
        if (mode === CHAT_MODE.CHATBOT) {
            return `${CHAT_STORAGE_CHAT_NAME_SPACE}-${communityId}`;
        } else {
            return CHAT_STORAGE_CHAT_NAME_SPACE;
        }
    }, [
        communityId
    ]);

    const getMessagesNamespace = useCallback((mode) => {
        mode = mode || storageType;

        if (mode === CHAT_MODE.CHATBOT) {
            return `${MESSAGES_STORAGE_NAME_SPACE}-${communityId}`;
        } else {
            return MESSAGES_STORAGE_NAME_SPACE;
        }
    }, [
        communityId
    ]);

    const saveChat = useCallback( (data) => {
        const namespace = getChatNamespace();
        const chat = storageUtils.getItem(namespace) || {};
        const newChat = {
            ...chat,
            ...data,
           expiresIn: moment().add(STORAGE_EXPIRY).toDate()
        };

        storageUtils.setItem(namespace, newChat);
    }, [
        getChatNamespace
    ]);

    const getChat = useCallback((mode) => {
        const namespace = getChatNamespace(mode);
        return storageUtils.getItem(namespace);
    }, [
        getChatNamespace
    ]);

    const getMessages = useCallback((mode) => {
       const namespace = getMessagesNamespace(mode);
       return storageUtils.getItem(namespace);
    }, [
        getMessagesNamespace
    ]);

    const saveMessages = useCallback((message) => {
        const namespace = getMessagesNamespace();
        const messages = storageUtils.getItem(namespace) || [];
        const newMessages = [...messages, message];

        storageUtils.setItem(namespace, newMessages);
    }, [
        getMessagesNamespace
    ]);

    const updateLastMessage = useCallback((message) => {
       const namespace = getMessagesNamespace();
       const messages = storageUtils.getItem(namespace);

       if (!messages) {
           return;
       }

       messages[messages.length - 1] = message;
       storageUtils.setItem(namespace, messages);
    }, [
       getMessagesNamespace
    ]);

    const clear = useCallback((mode) => {
       const chatNamespace = getChatNamespace(mode);
       const historyNamespace = getMessagesNamespace(mode);

       storageUtils.removeItem(chatNamespace);
       storageUtils.removeItem(historyNamespace);
    }, [getChatNamespace, getMessagesNamespace]);

    const getSessionId = useCallback(() => {
        const sessionData = storageUtils.getItem(CHAT_SESSION_ID_STORAGE_NAME_SPACE);

        if (!sessionData) {
            return null;
        }

        return moment(sessionData.expiresIn).isAfter(moment()) ? sessionData.sessionId : null;
    }, []);

    const saveSessionId = useCallback((sessionId) => {
        const sessionData = {
            sessionId,
            expiresIn: moment().add(STORAGE_EXPIRY).toDate()
        };
        storageUtils.setItem(CHAT_SESSION_ID_STORAGE_NAME_SPACE, sessionData);
    }, []);


    const contextValue = useMemo(() => ({
        setStorageType,
        getChatNamespace,
        getMessagesNamespace,
        getChat,
        saveChat,
        getMessages,
        saveMessages,
        updateLastMessage,
        clear,
        getSessionId,
        saveSessionId,
    }), [setStorageType, getChatNamespace, getMessagesNamespace, getChat, saveChat, getMessages, saveMessages, updateLastMessage, clear, getSessionId, saveSessionId]);

    return (
        <ChatStorageContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ChatStorageContext.Provider>
    );
}

ChatStorageProvider.propTypes = {
    apiKey: PropTypes.string,
    communityId: PropTypes.number,
    children: PropTypes.any.isRequired
};
