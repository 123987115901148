import React, {
    useState,
    useMemo,
    useEffect,
    useContext,
    createContext
} from 'react';
import PropTypes from 'prop-types';

function getShouldDisableChat() {
    if (window.zekiEditMode) {
        return true;
    }

    return false;
}

export const ParentWindowCommunicatorContext = createContext({});

export const useParentWindowCommunicator = () => {
    return useContext(ParentWindowCommunicatorContext);
};

export const ParentWindowCommunicatorProvider = ({ children }) => {
    const [isDesktop, setIsDesktop] = useState(true);
    // Default true so that the chatbot will start small
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: undefined,
        height: undefined
    });
    const [modalUrl, setModalUrl] = useState(null);

    const disableChat = useMemo(() => {
        return getShouldDisableChat();
    }, []);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            // eslint-disable-next-line no-unused-vars
            let data;
            try {
                data = JSON.parse(event.data);
            } catch (_) {
                // ignore
            }
            if (data?.type === 'OPEN_MODAL') {
                setModalUrl(data.url);
            }
            if (data?.type === 'WINDOW_RESIZED') {
                const { payload } = data;
                setDimensions(payload.dimensions);
                setIsDesktop(payload.isDesktop);
                setIsTablet(payload.isTablet);
                setIsMobile(payload.isMobile);
            }
        });

        const data = { type: 'INITIALIZE' };
        const message = JSON.stringify(data);
        window.top.postMessage(message, '*');
    }, []);
    const closeModal = useMemo(() => {
        return () => {
            setModalUrl(null);
        };
    }, []);
    
    const contextValue = useMemo(() => ({
        disableChat,
        dimensions,
        isDesktop,
        isTablet,
        isMobile,
        modalUrl,
        closeModal,
    }), [disableChat, dimensions, isDesktop, isTablet, isMobile, modalUrl, closeModal]);

    return (
        <ParentWindowCommunicatorContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ParentWindowCommunicatorContext.Provider>
    );
};

ParentWindowCommunicatorProvider.propTypes = {
    children: PropTypes.any.isRequired,
};
