import * as TokenAPI from 'api/token';
import { Client } from '@twilio/conversations';

export class TwilioChatClient {
    constructor({ events }) {
        this.events = events;
        this.token = null;
    }

    async initializeChat(existingToken) {
        if (existingToken) {
            this.token = existingToken;
        } else {
            const { token } = await TokenAPI.fetchToken();
            this.token = token;
        }

        this.chatClient = await Client.create(this.token);

        this.chatClient.on('tokenAboutToExpire', async () => {
            const { token: renewedToken } = await TokenAPI.fetchToken();
            await this.chatClient.updateToken(renewedToken);
        });

        this.chatClient.on('connectionError', (connectionError) => {
            this.events.errorOccurred(connectionError);
        });

        this.chatClient.on('conversationRemoved', this.events.channelRemoved);
    }

    async createChannel(channelName, description) {
        return await this.chatClient.createConversation({
            uniqueName: channelName,
            friendlyName: description || 'General Chat Channel'
        });
    }

    async joinChannel(channel) {
        await channel.join();
        this.subscribeToChannelEvents(channel);
    }

    subscribeToChannelEvents(channel) {
        channel.on('messageAdded', this.events.messageAdded);
        channel.on('participantJoined', this.events.memberJoined);
    }

    async getChannel(channelSid) {
        return this.chatClient.getConversationBySid(channelSid);
    }
}
