import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { messageType } from 'types';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import { MESSAGE_AUTHOR } from 'constants/Message';
import { getMessageAuthorType, formatMessageBodyMarkup } from 'utils/message';
import ChatIdentity from 'components/ChatIdentity/ChatIdentity';
import Units from 'components/Units/Units';
import MessageOptions from 'components/MessageOptions/MessageOptions';
import MessageList from 'components/MessageList/MessageList';
import MessageShowMore from 'components/MessageShowMore/MessageShowMore';
import styles from './Message.scss';
import { useChat } from 'components/Chat/ChatContext';
import {
    CHAT_MODE,
    EVENT_TYPE,
    REQUEST_EMAIL,
    REQUEST_PHONE,
    REQUEST_TEXT,
    REQUEST_TOUR,
    REQUEST_VIDEO_TOUR
} from 'constants/Chat';
import { MESSAGE_CLICK_HANDLER_OPTIONS } from 'constants/Message';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';

function SpinWrapper() {
    const { chatMode } = useChat();
    const [hasTimeoutError, setHasTimeoutError] = useState(false);

    useEffect(() => {
        if (chatMode === CHAT_MODE.CHATBOT) {
            const loadingTimeout = setTimeout(() => {
                setHasTimeoutError(true);
            }, 20000);
            return () => {
                clearTimeout(loadingTimeout);
            };
        }
    }, [chatMode, setHasTimeoutError]);

    if (hasTimeoutError) {
        throw new Error('Automated message timeout');
    }

    return <Spinner
        aria-label="Waiting for response"
        name="three-bounce"
        fadeIn="quarter"
        className={styles.spinner}
           />;
}

function Message ({
    message = {},
    awaitingResponse = false,
    scrollToBottom = null,
    configValues,
}) {
    const { setShowChatBotInformationForm, sessionId, funnelData } = useChat();
    const { payload, body } = message;
    const units = payload?.units;
    const unitsType = payload?.units_type;
    const more = payload?.more;
    const moreLabel = payload?.more_label;
    const listItems = payload?.list_items;
    const tourUrl = payload?.tour_url;
    const authorType = getMessageAuthorType(message);
    const hasUnits = units && units.length > 0;
    const hasOptions = payload?.options;
    const hasOnlyOptions = payload?.options && !body;
    const hasMore = !!more;
    const avatarUrl = configValues?.avatarUrl;
    const residentRedirectUrl = configValues?.residentRedirectUrl;
    const actionButton = payload?.action_button;
    const actionButtonType = actionButton?.type;
    const actionButtonText = actionButton?.text;
    const [ actionButtonClicked, setActionButtonClicked ]= useState(false);
    const actionButtonClickHandler = useCallback(() => {
        if (!actionButtonType) return null;
        setActionButtonClicked(actionButtonType);
    }, [actionButtonType]);

    useEffect(() => {
        switch (actionButtonClicked) {
            case MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_EMAIL:
                setShowChatBotInformationForm(REQUEST_EMAIL);
                trackChatbotEvent(sessionId, EVENT_TYPE.SELECT_CONTACT_FORM, { formType: REQUEST_EMAIL, ...funnelData });
                break;
            case MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_TEXT:
                setShowChatBotInformationForm(REQUEST_TEXT);
                trackChatbotEvent(sessionId, EVENT_TYPE.SELECT_CONTACT_FORM, { formType: REQUEST_TEXT, ...funnelData });
                break;
            case MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_TOUR:
                setShowChatBotInformationForm(REQUEST_TOUR);
                trackChatbotEvent(sessionId, EVENT_TYPE.SELECT_CONTACT_FORM, { formType: REQUEST_TOUR, ...funnelData });
                break;
            case MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_PHONE:
                setShowChatBotInformationForm(REQUEST_PHONE);
                trackChatbotEvent(sessionId, EVENT_TYPE.SELECT_CONTACT_FORM, { formType: REQUEST_PHONE, ...funnelData });
                break;
            case MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_VIRTUAL_TOUR:
                window.top.postMessage(JSON.stringify({ type: 'OPEN_MODAL', url: tourUrl }), '*');
                trackChatbotEvent(sessionId, EVENT_TYPE.BUTTON_CLICK, { eventType: REQUEST_VIDEO_TOUR, ...funnelData });
                setActionButtonClicked(false);
                break;
            default:
                break;
        }
    }, [actionButtonClicked, funnelData, message, sessionId, setShowChatBotInformationForm, tourUrl]);

    const authorLabel = {
        [MESSAGE_AUTHOR.SYSTEM]: 'Chatbot',
        [MESSAGE_AUTHOR.USER]: 'I',
        [MESSAGE_AUTHOR.FUNNEL]: payload?.liveAgent?.name
    };

    const authorSays = authorType === MESSAGE_AUTHOR.USER ? 'say' : 'says';
    const accessibilityLabel = `${authorLabel[authorType]} ${authorSays} `;

    const messageBodyWithMarkup = useMemo(() => {
        return formatMessageBodyMarkup(body, sessionId, funnelData);
    }, [body, funnelData, sessionId]);

    return (
        <>
            {!hasOnlyOptions && (
                <div
                    className={cx(styles.component, {
                        [styles[`component-${authorType}`]]: authorType,
                    })}
                    data-testid="message"
                    tabIndex="0"
                    aria-label={`${accessibilityLabel}${body}`}
                >
                    {authorType !== MESSAGE_AUTHOR.USER && (
                        <ChatIdentity authorType={authorType} liveAgent={payload?.liveAgent} avatarUrl={avatarUrl} />
                    )}
                    <div
                        aria-label={authorLabel[authorType]}
                        className={styles.content}
                    >
                        {!awaitingResponse && (
                            <span className="sr-only">
                                { accessibilityLabel }
                            </span>
                        )}

                        {awaitingResponse ? (
                            <SpinWrapper />
                        ) : messageBodyWithMarkup}

                        {hasMore && (
                            <MessageShowMore
                                more={more}
                                moreLabel={moreLabel}
                                scrollToBottom={scrollToBottom}
                                sessionId={sessionId}
                                funnelData={funnelData}
                            />
                        )}

                        {hasUnits && (
                            <Units
                                units={units}
                                unitsType={unitsType}
                            />
                        )}

                        {listItems && (
                            <MessageList items={listItems} sessionId={sessionId} funnelData={funnelData} />
                        )}
                        {actionButton && (
                            <a href="#" onClick={actionButtonClickHandler}>{actionButtonText}</a>
                        )}
                    </div>
                </div>
            )}
            {hasOptions && (
                <MessageOptions
                    payload={payload}
                    scrollToBottom={scrollToBottom}
                    residentRedirectUrl={residentRedirectUrl}
                />
            )}
        </>
    );
}

Message.propTypes = {
    awaitingResponse: PropTypes.bool,
    message: messageType,
    scrollToBottom: PropTypes.func,
    configValues: PropTypes.object
};

export default Message;
