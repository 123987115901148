import { useEffect, useState } from 'react';
import { MESSAGE_OPTIONS_LAYOUT_TYPE } from 'constants/Message';

const useChatPaddingBottom = (messages = []) => {
    const [padding, setPadding] = useState(0);

    useEffect(() => {
        if (messages.length === 0) return;

        const lastMessage = messages[messages.length -1];
        if (lastMessage?.payload?.options_layout_type === MESSAGE_OPTIONS_LAYOUT_TYPE.OVERLAY) {
            setPadding(162);
        } else {
            setPadding(0);
        }
    }, [
        messages
    ]);

    return padding;
};

export default useChatPaddingBottom;
