import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as ConversationAPI from 'api/conversation';
import * as ClientAPI from 'api/client';
import { getMessageAuthorType } from 'utils/message';
import { AUTHOR_TYPE, CHAT_MODE } from 'constants/Chat';
import { useConfig } from 'components/Config/ConfigContext';
import { useError } from 'components/ErrorContext/ErrorContext';

export const FunnelApiContext = createContext({});

export const useFunnelApi = () => useContext(FunnelApiContext);

export function FunnelApiProvider({ apiKey, communityId, leadSourceId, children }) {
    const { handleError } = useError();
    const { configValues: {
        employeeGroupId
    } } = useConfig();

    const funnelData = {
        conversationId: null,
        conversationType: null,
        clientId: null,
        leadSourceFromDni: null,
        campaignInfo: null,
    };

    const eitherLeadSourceId = leadSourceId || funnelData.leadSourceFromDni;

    const createConversation = useCallback(async (channelSid, conversationType) => {
        try {
            const data = {
                apiKey,
                community_id: communityId,
                channel_sid: channelSid,
                conversation_type: conversationType,
                website_url: window?.location.href
            };

            if (funnelData.clientId) {
                data.client_id = funnelData.clientId;
            }

            if (funnelData.conversationId) {
                data.previous_conversation_id = funnelData.conversationId;
            }

            const response = await ConversationAPI.createConversation(data);
            funnelData.conversationId = response.id;
            funnelData.conversationType = conversationType;
            return response;
        } catch (e) {
            handleError(e);
        }
    }, [apiKey, communityId, funnelData.clientId, funnelData.conversationId, funnelData.conversationType, handleError]);

    const updateConversation = useCallback(async (clientId) => {
        funnelData.clientId = clientId;

        try {
            return await ConversationAPI.updateConversation(funnelData.conversationId, {
                client_id: clientId,
                apiKey
            });
        } catch (e) {
            handleError(e);
        }
    }, [apiKey, funnelData, handleError]);

    const createClient = useCallback(async (client) => {
        const data = {
            apiKey,
            employeeGroupId,
            leadSourceId: eitherLeadSourceId,
            ...client
        };
        if (funnelData.campaignInfo) data.campaignInfo = funnelData.campaignInfo;

        const response = await ClientAPI.createClient(data);

        funnelData.clientId = response.data.client.id;
        return response.data;
    }, [apiKey, employeeGroupId, eitherLeadSourceId, funnelData.campaignInfo, funnelData.clientId]);

    const collectClientPII = useCallback(async (client) => {
        const data = {
            apiKey,
            employeeGroupId,
            leadSourceId: eitherLeadSourceId,
            ...client
        };
        if (funnelData.campaignInfo) data.campaignInfo = funnelData.campaignInfo;

        const response = await ClientAPI.collectClientPII(data);

        funnelData.clientId = response.data.client.id;
        return response.data;
    }, [apiKey, employeeGroupId, eitherLeadSourceId, funnelData.campaignInfo, funnelData.clientId]);

    const createMessage = useCallback(async (message) => {
        if (!funnelData.conversationId) {
            return;
        }

        // We are not logging live chat messages, this is done on the funnel side.
        if (funnelData.conversationType !== CHAT_MODE.CHATBOT) {
            return;
        }

        try {
            const authorType = getMessageAuthorType(message);
            await ConversationAPI.createMessage(funnelData.conversationId, {
                message: message.body,
                author_type: AUTHOR_TYPE[authorType],
                meta_data: message,
                apiKey,
            });
        } catch (e) {
            handleError(e);
        }
    }, [apiKey, funnelData.conversationId, funnelData.conversationType, handleError]);

    const contextValue = useMemo(() => ({
        createConversation,
        createClient,
        collectClientPII,
        createMessage,
        funnelData,
        updateConversation
    }), [
        createConversation,
        createClient,
        collectClientPII,
        createMessage,
        funnelData,
        updateConversation
    ]);

    return (
        <FunnelApiContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </FunnelApiContext.Provider>
    );
}

FunnelApiProvider.propTypes = {
    apiKey: PropTypes.string,
    communityId: PropTypes.number,
    leadSourceId: PropTypes.number,
    children: PropTypes.any.isRequired
};
