import React, {
    useState,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Button.scss';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';
import { EVENT_TYPE } from 'constants/Chat';

function Button ({
    children,
    className,
    variant,
    type,
    fullWidth,
    disabled,
    enableDoubleClick,
    onClick,
    sessionId,
    funnelData,
    ...props
}) {
    const [doubleClick, setDoubleClick] = useState(false);

    const onClickHandler = useCallback((event) => {
        if (!onClick || doubleClick) return;
        if (enableDoubleClick) {
            return onClick(event);
        }

        setDoubleClick(true);
        trackChatbotEvent(sessionId, EVENT_TYPE.BUTTON_CLICK, { buttonLabel: children, ...funnelData });
        onClick(event);
    }, [onClick, doubleClick, enableDoubleClick, sessionId, children, funnelData]);

    return (
        <button
            data-testid="button"
            className={cx(styles.component, {
                [className]: className,
                [styles[variant]]: variant,
                [styles.fullWidth]: fullWidth,
                [styles.disabled]: disabled
            })}
            disabled={disabled}
            type={type}
            onClick={onClickHandler}
            {...props}
        >
            <div className={styles.content}>
                {children}
            </div>
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['solid', 'outline', 'clear']).isRequired,
    type: PropTypes.oneOf(['button', 'submit']).isRequired,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    enableDoubleClick: PropTypes.bool,
    sessionId: PropTypes.string,
    funnelData: PropTypes.object
};

Button.defaultProps = {
    variant: 'solid',
    type: 'button',
    enableDoubleClick: false
};

export default Button;
