import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as env from 'utils/env';
import { URL } from 'config';
import { useIframe } from 'components/Iframe/IframeContext';
import { appendLinkToDocumentHead } from 'utils/document';

function IframeContent({ children }) {
    const { document, window } = useIframe();
    const [didLoadStylesheet, setDidLoadStylesheet] = useState(false);

    useEffect(() => {
        if (env.isProd) {
            appendLinkToDocumentHead(document, `${URL}/funnel-chat.min.css`)
                .then(() => {
                    setDidLoadStylesheet(true);
                });
        } else {
            const parentDocument = window.parent.document;
            document.head.innerHTML = parentDocument.head.innerHTML;
            setDidLoadStylesheet(true);
        }
    }, [
        window,
        document,
    ]);

    if (!didLoadStylesheet) {
        return null;
    }

    return children;
}

IframeContent.propTypes = {
    children: PropTypes.node.isRequired
};

export default IframeContent;
