import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { useIframe } from 'components/Iframe/IframeContext';
import { useConfig } from 'components/Config/ConfigContext';


function CSSVariableApplicator ({ defaultPaddingRight, defaultPaddingBottom, reactivePaddingRight, reactivePaddingBottom, children }) {
    const { configValues } = useConfig();
    const { document } = useIframe();
    const [muiTheme, setMuiTheme] = useState(null);

    // Use effect to set global css variables based on configuration values
    useEffect(() => {
        if (!configValues || !document) return;
        let primaryColor = Color('#000');

        if (configValues.color) {
            try {
                // The configValues.color is ALWAYS a hex color without the '#' character
                primaryColor = Color(`#${configValues.color}`);
            } catch (error) {
                // Ignore (configValues.color incorrect)
            }
        }

        document.documentElement.style.setProperty('--color-primary-main', primaryColor);
        document.documentElement.style.setProperty('--color-primary-contrast-text', primaryColor.isDark() ? '#fff' : '#000');
        document.documentElement.style.setProperty('--default-padding-right', defaultPaddingRight ? defaultPaddingRight : 0);
        document.documentElement.style.setProperty('--default-padding-bottom', defaultPaddingBottom ? defaultPaddingBottom : 0);
        document.documentElement.style.setProperty('--reactive-padding-right', reactivePaddingRight ? reactivePaddingRight : 0);
        document.documentElement.style.setProperty('--reactive-padding-bottom', reactivePaddingBottom ? reactivePaddingBottom : 0);

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: primaryColor.hex()
                }
            }
        });

        setMuiTheme(theme);
    }, [
        defaultPaddingRight,
        defaultPaddingBottom,
        reactivePaddingRight,
        reactivePaddingBottom,
        document,
        configValues
    ]);

    if (!muiTheme) {
        return null;
    }

    return (
        <MUIThemeProvider theme={muiTheme}>
            {children}
        </MUIThemeProvider>
    );
}

CSSVariableApplicator.propTypes = {
    defaultPaddingRight: PropTypes.string,
    defaultPaddingBottom: PropTypes.string,
    reactivePaddingRight: PropTypes.string,
    reactivePaddingBottom: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default CSSVariableApplicator;
