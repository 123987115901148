import * as UnitsTypes from 'constants/UnitsType';

export const getIsSimilarListing = (unitsType) => {
    return unitsType === UnitsTypes.SIMILAR;
};

export const getIsExactOrSimilarListing = (unitsType) => {
    return unitsType === UnitsTypes.SIMILAR ||
        unitsType === UnitsTypes.EXACT;
};

export const getIsNearbyListing = (unitsType) => {
    return unitsType === UnitsTypes.SISTER_SIMILAR ||
        unitsType === UnitsTypes.SISTER_EXACT;
};
