import { MESSAGE_AUTHOR } from './Message';

export const AUTHOR_TYPE = {
  [MESSAGE_AUTHOR.SYSTEM]: 10,
  [MESSAGE_AUTHOR.USER]: 20,
  [MESSAGE_AUTHOR.FUNNEL]: 30,
};

export const CHAT_MODE = {
  CHATBOT: 10,
  LIVE_CHAT: 20
};

export const CHAT_TYPE = {
  LIVE_ONLY: 10,
  AUTOMATED_ONLY: 20,
  LIVE_AND_AUTOMATED: 30
};

export const REQUEST_CONTACT = 'request_contact';
export const REQUEST_EMAIL = 'request_email';
export const REQUEST_TEXT = 'request_text';
export const REQUEST_TOUR = 'tour';
export const REQUEST_PHONE = 'request_phonecall';
export const REQUEST_VIDEO_TOUR = 'request_video_tour';

export const REQUEST_CONTACT_TYPES = [
  REQUEST_EMAIL,
  REQUEST_TEXT,
  REQUEST_PHONE,
];

export const ACTION_EMAIL = 'collect_pii_for_email';
export const ACTION_TEXT = 'collect_pii_for_text';
export const ACTION_TOUR = 'collect_pii_for_tour';
export const ACTION_PHONE = 'collect_pii_for_call';
export const ACTION_SEND_VIRTUAL_TOUR = 'send_video_tour';

export const DEFAULT_CHAT_TYPE = CHAT_TYPE.LIVE_AND_AUTOMATED;

export const CHAT_STATE = {
    DISCONNECTED: 'disconnected',
    INITIALISING: 'initialising',
    CONNECTED: 'connected'
};

export const LIVE_AGENT_STATUS = {
    CONNECTED: 'connected',
    DISCONNECTED: 'disconnected',
};

export const CHAT_STORAGE_CHAT_NAME_SPACE = 'funnel-chat';
export const MESSAGES_STORAGE_NAME_SPACE = 'funnel-messages';
export const CHAT_SESSION_ID_STORAGE_NAME_SPACE = 'funnel-chat-session-id';
export const STORAGE_EXPIRY = { hours: 4 }; // 4 hours, keep this number in sync with TWILIO_TTL on the backend

export const EVENT_TYPE = {
  OPEN_CHATBOT: 'open_chatbot',
  CLOSE_CHATBOT: 'close_chatbot',
  CLOSE_WELCOME_MESSAGE: 'close_welcome_message',
  BUTTON_CLICK: 'button_click',
  LIVE_CHAT_MESSAGE_SENT: 'live_chat_message_sent',
  CHATBOT_MESSAGE_SENT: 'chatbot_message_sent',
  SELECT_CONTACT_FORM: 'select_contact_form',
  FORM_SUBMIT: 'form_submit',
  MESSAGE_LINK_CLICKED: 'message_link_click',
  LIST_SHOW_MORE_CLICK: 'list_show_more_click',
  MESSAGE_SHOW_MORE_CLICK: 'message_show_more_click',
  REFRESH_CHAT: 'refresh_chat',
};

export const GA4_CATEGORIES = {
  CHATBOT_TRACKING: 'chatbot_tracking',
};

export const GA4_ACTIONS = {
  CHATBOT_INTERACTION: 'chatbot_interaction',
};
