import { useEffect } from 'react';

export function useKeyListener(targetKey, callback) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleKeyDown({ key }) {
        if (key.which === targetKey || key === targetKey) {
            callback();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export const KEYBOARD_INPUT = {
    TAB: 'Tab',
    ESC: 'Escape',
    SPACE: 'Space'
};
