import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import cx from 'classnames';

import IframeContent from 'components/Iframe/IframeContent';

// Note: 'simplified' duplicate of Iframe component
function Iframe ({ children, tooltipVisible }) {
    return (
        <Frame
            data-testid="iframe"
            className={cx('funnel-chat-iframe', { 'funnel-chat-popup-visible': tooltipVisible })}
        >
            <IframeContent>
                {children}
            </IframeContent>
        </Frame>
    );
}

Iframe.propTypes = {
    children: PropTypes.node.isRequired,
    tooltipVisible: PropTypes.bool
};

export default Iframe;
