import React from 'react';

export default function ChatSvg(props) {
    return (
        <svg width="42px" height="40px" viewBox="0 0 42 40" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
            <path d="M21,0 C9.40212,0 0,8.46585397 0,18.9091829 C0,22.7578352 1.28016,26.3356814 3.4734,29.3221411 C3.18528,32.7725988 2.3562,37.6327667 0,40 C0,40 7.19964,38.9851099 12.07668,36.0273337 C14.78568,37.1738305 17.80884,37.8183659 21,37.8183659 C32.59788,37.8183659 42,29.3525119 42,18.9091829 C42,8.46585397 32.59788,0 21,0 Z" id="Path" fill="currentColor"></path>
            <circle id="Oval" fill="#FFFFFF" cx="10.5" cy="19.5" r="2.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="21.5" cy="19.5" r="2.5"></circle>
            <circle id="Oval" fill="#FFFFFF" cx="31.5" cy="19.5" r="2.5"></circle>
        </svg>
    );
}
