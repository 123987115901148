import React, {
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
    useContext,
    createContext
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import * as BreakPoints from 'constants/BreakPoint';

const debouncer = debounce(
    f => f(),
    300,
    { leading: true }
);

export const ParentWindowContext = createContext({});

export const useParentWindow = () => {
    return useContext(ParentWindowContext);
};

export const ParentWindowProvider = ({
    children,
    window
}) => {
    const defaultOverflow = useRef();
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: undefined,
        height: undefined
    });

    // Use effect to determine parent default overflow style
    useEffect(() => {
        defaultOverflow.current = getComputedStyle(window.parent.document.body).overflow;
    }, [
        window
    ]);

    const handleWindowResize = useCallback(() => debouncer(() => {
        const width = window.parent.document.documentElement.clientWidth;
        const height = window.parent.document.documentElement.clientHeight;
        setDimensions({
            width,
            height
        });

        if (width >= BreakPoints.DESKTOP) {
            setIsDesktop(true);
            setIsTablet(false);
            setIsMobile(false);
        } else if (width >= BreakPoints.TABLET) {
            setIsDesktop(false);
            setIsTablet(true);
            setIsMobile(false);
        } else {
            setIsDesktop(false);
            setIsTablet(false);
            setIsMobile(true);
        }
    }), [
        window
    ]);

    const toggleScrollbar = useCallback((hideScrollbar) => {
        if (!defaultOverflow.current) return;

        if (hideScrollbar) {
            window.parent.document.body.style.overflow = 'hidden';
        } else {
            window.parent.document.body.style.overflow = defaultOverflow.current;
        }
    }, [
        window,
        defaultOverflow
    ]);

    useEffect(() => {
        window.parent.addEventListener('resize', handleWindowResize);

        return () => {
            window.parent.removeEventListener('resize', handleWindowResize);
        };
    });

    // Call handleWindowResize to determine initial screen dimensions
    useEffect(() => {
        handleWindowResize();
    }, [handleWindowResize]);

    const contextValue = useMemo(() => ({
        dimensions,
        isDesktop,
        isTablet,
        isMobile,
        window,
        toggleScrollbar
    }), [
        dimensions,
        isDesktop,
        isTablet,
        isMobile,
        window,
        toggleScrollbar
    ]);

    return (
        <ParentWindowContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ParentWindowContext.Provider>
    );
};

ParentWindowProvider.propTypes = {
    children: PropTypes.any.isRequired,
    window: PropTypes.any.isRequired
};
