import React from 'react';
import cx from 'classnames';
import { authorType as authorPropType } from 'types';
import { MESSAGE_AUTHOR } from 'constants/Message';
import ChatIcon from 'components/Svg/ChatSvg';
import styles from './ChatIdentity.scss';

function ChatIdentity ({ authorType, liveAgent, avatarUrl }) {
    if(authorType === MESSAGE_AUTHOR.USER) {
        return null;
    }
    if(authorType === MESSAGE_AUTHOR.SYSTEM) {
        if(avatarUrl) {
            return (
                <div className={styles.component} data-testid="chat-msg-icon">
                    <img src={avatarUrl}/>
                </div>
            );
        } else {
            return (
                <div className={styles.component} data-testid="chat-msg-icon">
                    <ChatIcon className={styles.icon}/>
                </div>
            );
        }
    } else {
        return (
            <div className={cx(styles.component, styles.liveAgent)} data-testid="live-agent-icon">
                {liveAgent?.name[0]?.toUpperCase()}
            </div>
        );
    }
}

ChatIdentity.propTypes = {
    authorType: authorPropType
};

export default ChatIdentity;
