import PropTypes from 'prop-types';
import {
    MESSAGE_TASK,
    MESSAGE_QUESTION,
    MESSAGE_OPTIONS_LAYOUT_TYPE,
    MESSAGE_AUTHOR
} from 'constants/Message';
import * as UnitsTypes from 'constants/UnitsType';

export const messagePayloadType = PropTypes.shape({
    task: PropTypes.oneOf(Object.values(MESSAGE_TASK).map(t => t)),
    question: PropTypes.oneOf(Object.values(MESSAGE_QUESTION).map(q => q)),
    list_items: PropTypes.array,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    ),
    more_options: PropTypes.array,
    options_layout_type: PropTypes.oneOf(Object.values(MESSAGE_OPTIONS_LAYOUT_TYPE).map(t => t)),
    layout_header: PropTypes.string,
    layout_sub_header: PropTypes.string,
    options_multi_select: PropTypes.bool,
    selected: PropTypes.any,
    more: PropTypes.string,
    more_label: PropTypes.string,
    disable_message_input: PropTypes.bool,
});

export const messageType = PropTypes.shape({
    author: PropTypes.string.isRequired,
    body: PropTypes.string,
    payload: messagePayloadType
});

export const unitsTypeType = PropTypes.oneOf([
    UnitsTypes.EXACT,
    UnitsTypes.SIMILAR,
    UnitsTypes.SISTER_EXACT,
    UnitsTypes.SISTER_SIMILAR
]);

export const unitType = PropTypes.shape({
    community_id: PropTypes.number,
    community_name: PropTypes.string,
    square_footage: PropTypes.number,
    date_available: PropTypes.string,
    unit_number: PropTypes.string,
    image_url: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
    layout: PropTypes.string,
    website_url: PropTypes.string
});

export const authorType = PropTypes.oneOf(Object.values(MESSAGE_AUTHOR));

export const liveAgentType = PropTypes.shape({
    name: PropTypes.string
});
