import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from 'components/Button/Button';
import TextField from 'components/FormField/TextField';
import CloseSvg from 'components/Svg/CloseSvg';
import styles from '../LiveChatForm/LiveChatForm.scss';

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Please fill in this field'),
    lastName: Yup.string()
        .required('Please fill in this field'),
    email: Yup.string().email('Invalid email address').when('phone', {
        is: (phone) => !phone || phone.length === 0,
        then: Yup.string().email().required('Email or Phone Required'),
        otherwise: Yup.string()
    }),
    phone: Yup.string().matches(phoneRegEx, 'Invalid Phone Number').when('email', {
        is: (email) => !email || email.length === 0,
        then: Yup.string().required('Email or Phone Required'),
        otherwise: Yup.string().nullable()
    }) },
    [['email', 'phone']]
);

function ChatBotInformationForm ({
    submitChatbotInformationForm,
    displayCloseButton,
    closeForm,
    requestType,
}) {
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                requestType: requestType,
            }}
            validationSchema={validationSchema}
            onSubmit={submitChatbotInformationForm}
        >
            {({ dirty, isValid }) => {
                return (
                    <div className={styles.component} data-testid="live-chat-form">
                        {displayCloseButton && (
                            <a href="#" onClick={closeForm} aria-label="Close form">
                                <CloseSvg
                                    data-testid="livechat-form-close"
                                    className={styles.closeIcon}
                                    onClick={closeForm}
                                />
                            </a>
                        )}
                        <div className={styles.header}>Contact Information</div>
                        <div className={styles.subHeader}>Tell us a little about yourself</div>
                        <Form className={styles.form}>
                            <div className={styles.formContent}>
                                <TextField
                                    margin="normal"
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                />
                                <TextField
                                    margin="normal"
                                    name="lastName"
                                    type="text"
                                    label="Last Name"
                                />
                                <TextField
                                    margin="normal"
                                    name="email"
                                    type="email"
                                    label="Email Address"
                                />
                                <TextField
                                    margin="normal"
                                    name="phone"
                                    type="phone"
                                    label="Phone Number"
                                />
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={!dirty || !isValid}
                                    className={styles.submit}
                                >
                                    Next
                                </Button>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}

ChatBotInformationForm.propTypes = {
    submitChatbotInformationForm: PropTypes.func.isRequired,
    displayCloseButton: PropTypes.bool,
    closeForm: PropTypes.func,
    requestType: PropTypes.string.isRequired
};

export default ChatBotInformationForm;
