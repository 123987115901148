import React from 'react';
import PropTypes from 'prop-types';
import styles from './ChatFooter.scss';
import { DEFAULT_BRANDING_URL } from 'config';

function ChatFooter({ brandingEnabled, resmanBrandingUrl, }) {
    if (!brandingEnabled) {
        return null;
    }

    const getHyperlink = () => {
        if (brandingEnabled) {
            return resmanBrandingUrl || DEFAULT_BRANDING_URL;
        }
        else {
            return '';
        }
    };

    const getLogoStyling = () => {
        if (brandingEnabled && resmanBrandingUrl) {
            return {
                backgroundImage: `url(/public/images/resman-logo.png)`,
                marginRight: 0,
                marginLeft: '4px',
                width: '54px',
            };
        }
    };

    return (
        <div>
            {/* eslint-disable react/jsx-no-target-blank */}
            <a
                href={getHyperlink()}
                target="_blank"
                className={styles.poweredBy}
            >
                {resmanBrandingUrl && 'Powered by'}
                <div
                    className={styles.poweredByImage}
                    style={getLogoStyling()}
                />
                {!resmanBrandingUrl && 'Powered by Funnel'}
            </a>
        </div>
    );
}

ChatFooter.propTypes = {
    brandingEnabled: PropTypes.bool,
    resmanBrandingUrl: PropTypes.string,
};

export default ChatFooter;
