import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip/Tooltip';
import RefreshIcon from 'components/Svg/SyncAltSolidSVG';
import WindowMinimizeIcon from 'components/Svg/WindowMinimizeSolidSVG';
import styles from './ChatHeader.scss';

export const ICON_BUTTON_CLICK_HANDLER_DELAY = 350;

function ChatHeader ({
    configValues,
    handleCloseChat,
    handleRefreshChat
}) {
    const handleIconButtonClick = useCallback((event, clickHandler) => {
       setTimeout(() => {
           clickHandler(event);
       }, ICON_BUTTON_CLICK_HANDLER_DELAY);
    }, []);

    return (
        <div className={styles.component}>
            {configValues?.logoUrl ? (
                <div
                    data-testid="chat-header-logo"
                    className={styles.headerLogo}
                    style={{ backgroundImage: `url("${configValues.logoUrl}")` }}
                />
            ): configValues?.name && (
                <div
                    data-testid="chat-header-text-logo"
                    className={styles.headerTextLogo}
                >
                    {configValues.name}
                </div>
            )}
            <div className={styles.headerIconsWrapper}>
                <Tooltip
                    title="Restart chat"
                    position="top"
                    leftOffset={16}
                    topOffset={16}
                >
                    <a
                        href="#"
                        aria-label="Refresh chat"
                        onClick={e => handleIconButtonClick(e, handleRefreshChat)}
                        role="button"
                    >
                        <RefreshIcon className={styles.headerIcon} />
                    </a>
                </Tooltip>
                <Tooltip
                    title="Minimize chat"
                    position="top"
                    leftOffset={16}
                    topOffset={16}
                >
                    <a
                        href="#"
                        aria-label="Minimize chat"
                        onClick={e => handleIconButtonClick(e, handleCloseChat)}
                        role="button"
                    >
                        <WindowMinimizeIcon className={styles.headerIcon} />
                    </a>
                </Tooltip>
            </div>
        </div>
    );
}

ChatHeader.propTypes = {
    configValues: PropTypes.object,
    handleCloseChat: PropTypes.func.isRequired,
    handleRefreshChat: PropTypes.func.isRequired
};

export default ChatHeader;
