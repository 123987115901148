import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@material-ui/core';
import { useField } from 'formik';
import styles from './TextField.scss';

function TextField (props) {
    const [field, meta] = useField(props);
    const { name, height, endAdornment, ...rest } = props;
    const labelOffset = -6;

    return (
        <div className={styles.component}>
            <MuiTextField
                variant="outlined"
                className={styles.textField}
                error={!!(meta.touched && meta.error)}
                style={{ height }}
                InputLabelProps={{
                    style: {
                        height,
                        top: `${labelOffset}px`,
                        fontSize: '14px'
                    },
                }}
                InputProps={{
                    endAdornment
                }}
                inputProps={{
                    style: {
                        height,
                        padding: '0 14px',
                        fontSize: '14px'
                    },
                    'data-testid': `text-field-${name}`
                }}
                {...field}
                {...rest}
            />
        </div>
    );
}

TextField.propTypes = {
    name: PropTypes.string,
    height: PropTypes.number,
    endAdornment: PropTypes.node,
};

TextField.defaultProps = {
    height: 44
};

export default TextField;
