import React from 'react';
import { render } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'regenerator-runtime/runtime';

import * as env from 'utils/env';
import { setupSentry } from 'utils/sentry';
import { appendLinkToDocumentHead } from 'utils/document';
import { URL } from 'config';
import { ParentWindowCommunicatorProvider } from 'components/ParentWindowCommunicator/ParentWindowCommunicator';
import { ChatProvider } from 'components/Chat/ChatContext';
import { ConfigProvider } from 'components/Config/ConfigContext';
import { FunnelApiProvider } from 'components/Chat/FunnelApiContext';
import { ErrorProvider } from 'components/ErrorContext/ErrorContext';
import { StartChatButtonProvider } from 'components/StartChatButton/StartChatButtonContext';
import { ChatStorageProvider } from 'components/ChatStorage/ChatStorageContext';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Iframe from 'components/Iframe/Iframe';
import CSSVariableApplicator from 'components/CSSVariableApplicator/CSSVariableApplicator';
import App from 'components/App/App';
import TourModal from 'components/TourModal/TourModal';

const containerId = `funnel-chat-container-${uuidv4()}`;
let initializeOptions  = null;

setupSentry();

// Note: in dev we need include some files manually.
// In prod these get added dynamically or are part of the integration.
if (env.isDev) {
    require('public/css/funnel-chat.iframe.css');
    require('public/css/tour-modal.css');
    require('public/js/parent-window-communicator');
}

async function renderChat({
    communityId,
    apiKey,
    defaultPaddingRight,
    defaultPaddingBottom,
    reactivePaddingRight,
    reactivePaddingBottom,
    leadSourceId = null,
    didPreLoadBundles = false,
}) {
    const chatContainer = document.createElement('div');
    chatContainer.setAttribute('id', containerId);
    document.body.appendChild(chatContainer);

    if (env.isProd && !didPreLoadBundles) {
        await appendLinkToDocumentHead(document, `${URL}/public/css/funnel-chat.iframe.css`);
        await appendLinkToDocumentHead(document, `${URL}/public/css/tour-modal.css`);
        // await appendLinkToDocumentHead(document, `${URL}/funnel-chat.min.css`);
        // ^ this was terrible and put the whole css in the host page's head
    }

    render(
        <ErrorBoundary>
            <ErrorProvider>
                <ParentWindowCommunicatorProvider>
                    <TourModal />
                    <ConfigProvider
                        communityId={communityId}
                        apiKey={apiKey}
                    >
                        <FunnelApiProvider
                            communityId={communityId}
                            apiKey={apiKey}
                            leadSourceId={leadSourceId}
                        >
                            <ChatStorageProvider
                                communityId={communityId}
                            >
                                <ChatProvider
                                    communityId={communityId}
                                    apiKey={apiKey}
                                    leadSourceId={leadSourceId}
                                >
                                    <StartChatButtonProvider>
                                        <CSSVariableApplicator
                                            defaultPaddingRight={defaultPaddingRight}
                                            defaultPaddingBottom={defaultPaddingBottom}
                                            reactivePaddingRight={reactivePaddingRight}
                                            reactivePaddingBottom={reactivePaddingBottom}
                                        >
                                            <Iframe>
                                                <CSSVariableApplicator>
                                                    <App />
                                                </CSSVariableApplicator>
                                            </Iframe>
                                        </CSSVariableApplicator>
                                    </StartChatButtonProvider>
                                </ChatProvider>
                            </ChatStorageProvider>
                        </FunnelApiProvider>
                    </ConfigProvider>
                </ParentWindowCommunicatorProvider>
            </ErrorProvider>
        </ErrorBoundary>,
        document.getElementById(containerId)
    );
}

function restart() {
    document.getElementById(containerId).remove();

    renderChat({
        ...initializeOptions,
        timeout: 0
    });
}

async function initialize(options) {
    initializeOptions = options;

    if (!options.didPreLoadBundles) {
        await new Promise(resolve => setTimeout(resolve, options.timeout || 0));
    }

    renderChat(options);
}

function setLeadSource(leadSourceId) {
    // Use this to set the lead source if we get it through asyncronously such as from DNI
    // We store the lead source in a global variable, so it's availble to widgets that load
    // after this is called

    window._fnlLeadSource = leadSourceId;
}

function setCampaignInfo(campaignInfo) {
    // Use this to set the campaign info if we get it through asyncronously such as from DNI
    // We store the campaign info in a global variable, so it's availble to widgets that load
    // after this is called
    window._fnlCampaignInfo = campaignInfo;
}

window.FunnelChat = {
    initialize,
    restart,
    setLeadSource,
    setCampaignInfo
};
