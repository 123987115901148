import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { liveAgentType } from 'types';
import styles from './LiveChatStatusBar.scss';
import { CHAT_STATE, LIVE_AGENT_STATUS } from 'constants/Chat';
import { useChat } from 'components/Chat/ChatContext';
import Spinner from 'react-spinkit';

function LiveChatStatusBar ({ liveAgent, scrollToBottom }) {

    useEffect(() => {
        scrollToBottom();
    });

    const {
        initializeLiveChat,
        chatState
    } = useChat();

    return (
        <>
            {!liveAgent && (
                <>
                    <div className={styles.component}>
                        <span className={styles.statusInfo}>
                            Someone will be with you soon.
                        </span>
                    </div>
                    <div className={styles.spinnerContainer}>
                        <Spinner name="three-bounce" className={styles.spinner} />
                    </div>
                </>
            )}
            {liveAgent?.status === LIVE_AGENT_STATUS.CONNECTED && (
                <>
                    <div className={styles.component}>
                        <div className={styles.statusInfo} data-testid="agent-joined-status">
                            <b>{liveAgent.name}</b> has joined the chat.
                        </div>
                    </div>
                    <div className={styles.iconWrapper}>
                        <div className={styles.icon}>
                            {liveAgent.name[0].toUpperCase()}
                        </div>
                    </div>
                </>
            )}
            {liveAgent?.status === LIVE_AGENT_STATUS.DISCONNECTED && (
                <div className={styles.component}>
                    <div className={styles.statusInfo} data-testid="agent-left-status">
                        <b>{liveAgent.name}</b> has left the chat.
                    </div>
                    {chatState === CHAT_STATE.DISCONNECTED && (
                        <div className={styles.statusInfo} data-testid="agent-left-reinitialize-chat">
                            Not finished? <a className={styles.link} onClick={() => initializeLiveChat({ message: 'Reconnect me' }, false)}>Click here</a> to speak with another agent.
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

LiveChatStatusBar.propTypes = {
    liveAgent: liveAgentType,
    scrollToBottom: PropTypes.func.isRequired
};

export default LiveChatStatusBar;
