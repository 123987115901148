const env = process.env.NODE_ENV || 'development';
const isDev = env === 'development';
const isProd = env === 'production';
const isTest = env === 'test';

module.exports = {
    env,
    isDev,
    isProd,
    isTest
};
