import React from 'react';
import PropTypes from 'prop-types';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { jssPreset } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import cx from 'classnames';

import IframeContent from './IframeContent';
import { useParentWindowCommunicator } from 'components/ParentWindowCommunicator/ParentWindowCommunicator';
import { useChat } from 'components/Chat/ChatContext';
import { useStartChatButton } from 'components/StartChatButton/StartChatButtonContext';


function Iframe ({ children }) {
    const { isDesktop, disableChat } = useParentWindowCommunicator();
    const { isOpen, hasUnreadMessages } = useChat();
    const { isPopupMessageVisible } = useStartChatButton();
    const isFullScreen = !isDesktop && isOpen;
    const isUnreadNotificationVisible = !isOpen && hasUnreadMessages;
    const extraClassNames = !disableChat && {
        ['funnel-chat-iframe-opened']: isOpen,
        ['funnel-chat-iframe-small-screen-opened']: isFullScreen,
        ['funnel-chat-popup-visible']: isPopupMessageVisible,
        ['funnel-chat-unread-notification-visible']: isUnreadNotificationVisible
    };

    return (
        <Frame
            title="Chatbot"
            data-testid="iframe"
            className={cx('funnel-chat-iframe', extraClassNames)}
        >
            <FrameContextConsumer>
                {({ document }) => {
                    const jss = create({
                        plugins: [...jssPreset().plugins],
                        insertionPoint: document.head
                    });

                    return (
                        <StylesProvider jss={jss}>
                            <IframeContent>
                                {children}
                            </IframeContent>
                        </StylesProvider>
                    );
                }}
            </FrameContextConsumer>
        </Frame>
    );
}

Iframe.propTypes = {
    children: PropTypes.node.isRequired
};

export default Iframe;
