import React from 'react';
import PropTypes from 'prop-types';
import { MESSAGE_AUTHOR } from 'constants/Message';
import { CHAT_MODE } from 'constants/Chat';
import useChatPaddingBottom from 'hooks/useChatPaddingBottom';
import Message from 'components/Message/Message';
import LiveChatStatusBar from 'components/LiveChatStatusBar/LiveChatStatusBar';
import styles from './ChatMessages.scss';

function ChatMessages ({
    awaitingResponse,
    messages,
    reference,
    scrollToBottom,
    chatMode,
    configValues
}) {

    const paddingBottom = useChatPaddingBottom(messages);

    return (
        <div
            className={styles.component}
            data-testid="messages"
            ref={reference}
            style={{ paddingBottom }}
        >
            {messages.map((message, index) => {
                return (
                    <React.Fragment key={index}>
                        <Message
                            message={message}
                            scrollToBottom={scrollToBottom}
                            configValues={configValues}
                        />
                        {message?.payload?.hasLiveAgentData && (
                            <LiveChatStatusBar
                                liveAgent={message?.payload?.liveAgent}
                                scrollToBottom={scrollToBottom}
                            />
                        )}
                    </React.Fragment>
                );
            })}
            {awaitingResponse && chatMode !== CHAT_MODE.LIVE_CHAT && (
                <Message
                    awaitingResponse={awaitingResponse}
                    message={{ author: MESSAGE_AUTHOR.SYSTEM }}
                    configValues={configValues}
                />
            )}
        </div>
    );
}

ChatMessages.propTypes = {
    awaitingResponse: PropTypes.bool,
    chatMode: PropTypes.oneOf([CHAT_MODE.CHATBOT, CHAT_MODE.LIVE_CHAT]),
    messages: PropTypes.array.isRequired,
    reference: PropTypes.object,
    scrollToBottom: PropTypes.func.isRequired,
    configValues: PropTypes.object.isRequired
};

export default ChatMessages;
