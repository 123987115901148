import React from 'react';

export default function SendSvg(props) {
    return (
        <svg width="25px" height="22px" viewBox="0 0 25 22" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
            <g id="MVP-Mobile-Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.1-End-Flow-Redirect_no-keyboard-community" transform="translate(-323.000000, -575.000000)">
                    <g id="Input" transform="translate(0.000000, 561.000000)">
                        <g>
                            <g id="IMessage" transform="translate(16.000000, 8.000000)">
                                <rect id="Field" fill="none" x="0" y="0" width="343" height="34" rx="17"></rect>
                                <polygon id="Path" fill="currentColor" fillRule="nonzero" points="307.420024 27.5 331.4999 18.5 307.419922 6.382874 312.4999 17.5"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
