import request from 'utils/request';
import { toQuery } from 'utils/formatting';

export const createClient = (data) => {
    const requestBody = {
        full_name: data.fullName,
        email: data.email,
        group_id: data.employeeGroupId,
        lead_source_id: data.leadSourceId
    };
    if (data.campaignInfo) requestBody.campaign_info = toQuery(data.campaignInfo);

    return request(`client/?api_key=${data.apiKey}`,  {
        method: 'POST',
        data: requestBody
    }).then(([body]) => body);
};

export const collectClientPII = (data) => {
    const requestBody = {
        people: [
            {
                'first_name': data.firstName,
                'last_name': data.lastName,
                'email': data.email,
                'phone_1': data.phone
            }
        ],
        group: data.employeeGroupId,
        lead_source: data.leadSourceId
    };
    if (data.campaignInfo) requestBody.campaign_info = toQuery(data.campaignInfo);

    return request(`clients/?api_key=${data.apiKey}`,  {
        method: 'POST',
        data: requestBody
    }).then(([body]) => body);
};
