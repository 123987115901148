import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isDev } from 'utils/env';
import { logToSentry } from 'utils/sentry';

export const ErrorContext = createContext({});

export const useError = () => {
    return useContext(ErrorContext);
};

export function ErrorProvider({ children }) {
    const [error, setError] = useState(null);

    const handleError = useCallback((newError) => {
        isDev && console.error(newError);
        setError(newError);
        logToSentry(newError);
    }, [
        setError
    ]);

    const contextValue = useMemo(() => ({
        handleError,
        error
    }), [
        handleError,
        error
    ]);

    return (
        <ErrorContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ErrorContext.Provider>
    );
}

ErrorProvider.propTypes = {
    apiKey: PropTypes.string,
    communityId: PropTypes.number,
    children: PropTypes.any.isRequired
};
