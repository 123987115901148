import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from 'components/Button/Button';
import TextField from 'components/FormField/TextField';
import CloseSvg from 'components/Svg/CloseSvg';
import styles from './LiveChatForm.scss';

const validationSchema = Yup.object({
    fullName: Yup.string()
        .required('Please fill in this field'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Please fill in this field'),
    message: Yup.string()
        .required('Please fill in this field'),
});


function LiveChatForm ({
    initializeLiveChat,
    displayCloseButton,
    closeForm
}) {
    return (
        <Formik
            initialValues={{
                fullName: '',
                email: '',
                message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={initializeLiveChat}
        >
            {({ dirty, isValid }) => {
                return (
                    <div className={styles.component} data-testid="live-chat-form">
                        {displayCloseButton && (
                            <a href="#" onClick={closeForm} aria-label="Close form">
                                <CloseSvg
                                    data-testid="livechat-form-close"
                                    className={styles.closeIcon}
                                    onClick={closeForm}
                                />
                            </a>
                        )}
                        <div className={styles.header}>Start A Chat</div>
                        <div className={styles.subHeader}>Tell us a little about yourself</div>
                        <Form className={styles.form}>
                            <div className={styles.formContent}>
                                <TextField
                                    margin="normal"
                                    name="fullName"
                                    type="text"
                                    label="Full Name"
                                />
                                <TextField
                                    margin="normal"
                                    name="email"
                                    type="email"
                                    label="Email Address"
                                />
                                <TextField
                                    margin="normal"
                                    name="message"
                                    type="text"
                                    label="How can we help you?"
                                />
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={!dirty || !isValid}
                                    className={styles.submit}
                                >
                                    Next
                                </Button>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}

LiveChatForm.propTypes = {
    initializeLiveChat: PropTypes.func.isRequired,
    displayCloseButton: PropTypes.bool,
    closeForm: PropTypes.func
};

export default LiveChatForm;
