import { useEffect } from 'react';

const useAutoFocus = (ref) => {
    useEffect(() => {
        ref.current && ref.current.focus();
    }, [
        ref
    ]);
};

export default useAutoFocus;
