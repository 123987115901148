import moment from 'moment';

export const dateToMMMDo = (value) => {
    if (!value) return null;

    return moment(value).format('MMM Do');
};

export const dateTodddMMMDo = (value) => {
    if (!value) return null;

    return moment(value).format('ddd, MMM Do');
};

export const timeTohhmma = (value) => {
    if (!value) return null;

    return moment(value,'HH:mm').format('hh:mm a');
};
