import * as OptionActionTypes from 'constants/OptionActionTypes';

export const MESSAGE_TASK = {
    COLLECT_DATA: 'task-collect-data',
    CONTACT_AGENT: 'task-contact-agent'
};

export const MESSAGE_QUESTION = {
    APPOINTMENT_TIME: 'question-appointment-time',
    APPOINTMENT_DATE: 'question-appointment-date',
    APPOINTMENT_TOUR_TYPE: 'question-appointment-tour-type',
    APPOINTMENT_PLATFORM_OF_CHOICE: 'question-appointment-platform-of-choice',
    APPOINTMENT_LAYOUT_PREFERENCES: 'question-appointment-layout-preferences',
};

export const MESSAGE_OPTIONS_LAYOUT_TYPE = {
    OVERLAY: 'overlay'
};

export const MESSAGE_OPTION_CHAT_WITH_AGENT = 'Chat with Agent';

export const MESSAGE_AUTHOR = {
    SYSTEM: 'system',
    USER: 'user',
    FUNNEL: 'funnel'
};

export const RESIDENT_QUERY_OPTIONS = [
    {
        'label': 'Yes',
        'action': OptionActionTypes.RESIDENT_AFFIRMATIVE_OPTION,
    }, {
        'label': 'No',
        'action': OptionActionTypes.RESIDENT_NEGATIVE_OPTION,
    }
];

export const RESIDENT_REDIRECT_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'Are you a resident at ${communityName}.',
    attributes: {
        options: [
            { label: 'Yes', action: OptionActionTypes.RESIDENT_AFFIRMATIVE_OPTION },
            { label: 'No', action: OptionActionTypes.RESIDENT_NEGATIVE_OPTION }
        ]
    }
};

export const INITIAL_FUNNEL_NLP_MESSAGES = [
    {
        author: MESSAGE_AUTHOR.SYSTEM,
        body: `You can ask me things like:\n
            "Can I schedule a tour for tomorrow?"\n
            "Do you have any one bedrooms available for an October move in?"
            or select one of the buttons below! 👇`,
        attributes: {
            options: [
                'Schedule Tour',
                'Contact Agent',
                'Community Amenities',
                'Apartment Features',
                'Pet Policy'
            ]
        }
    }];

export const MESSAGE_CLICK_HANDLER_OPTIONS = {
    SHOW_CHATBOT_INFORMATION_FORM_CONTACT_EMAIL: 'SHOW_CHATBOT_INFORMATION_FORM_CONTACT_EMAIL',
    SHOW_CHATBOT_INFORMATION_FORM_CONTACT_TEXT: 'SHOW_CHATBOT_INFORMATION_FORM_CONTACT_TEXT',
    SHOW_CHATBOT_INFORMATION_FORM_TOUR: 'SHOW_CHATBOT_INFORMATION_FORM_TOUR',
    SHOW_CHATBOT_INFORMATION_FORM_CONTACT_PHONE: 'SHOW_CHATBOT_INFORMATION_FORM_CONTACT_PHONE',
    SHOW_VIRTUAL_TOUR: 'SHOW_VIRTUAL_TOUR',
};

export const REQUEST_PII_FOR_TOUR_INTRO_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'Let\'s schedule a tour! Before I do, I just need to know who to put it down for. Please enter your information ',
    attributes: {
        action_button: {
            text: 'here.',
            type: MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_TOUR
        }
    }
};

export const REQUEST_PII_FOR_TEXT_INTRO_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'I\'ll be happy to get an agent to send you a text. Before I do, I just need to collect some contact information. Please enter it ',
    attributes: {
        action_button: {
            text: 'here.',
            type: MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_TEXT
        }
    }
};

export const REQUEST_PII_FOR_EMAIL_INTRO_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'I\'ll be happy to get an agent to send you an email. Before I do, I just need to collect some contact information. Please enter it ',
    attributes: {
        action_button: {
            text: 'here.',
            type: MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_EMAIL
        }
    }
};

export const REQUEST_PII_FOR_PHONE_INTRO_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'I\'ll be happy to have an agent give you a call. Before I do, I just need to collect some contact information. Please enter it ',
    attributes: {
        action_button: {
            text: 'here.',
            type: MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_CHATBOT_INFORMATION_FORM_CONTACT_PHONE
        }
    }
};

export const PROVIDE_VIRTUAL_TOUR_LINK_MESSAGE = {
    author: MESSAGE_AUTHOR.SYSTEM,
    body: 'Check out a video of the community ',
    attributes: {
        action_button: {
            text: 'here.',
            type: MESSAGE_CLICK_HANDLER_OPTIONS.SHOW_VIRTUAL_TOUR
        },
    }
};
