import request from 'utils/request';
import { logToSentry } from 'utils/sentry';
import ReactGA from 'react-ga4';
import { GA4_ACTIONS, GA4_CATEGORIES } from 'constants/Chat';

export const trackChatbotEvent = async (sessionId, eventType, eventData = {}) => {
    try {
        await request(`track-chatbot-event/`, {
            method: 'POST',
            data: {
                sessionId,
                eventType,
                ...eventData,
            },
        });

        if (ReactGA.isInitialized) {
            ReactGA.event({
                category: GA4_CATEGORIES.CHATBOT_TRACKING,
                action: GA4_ACTIONS.CHATBOT_INTERACTION,
                label: eventType,
                ...eventData
            });
        }

    } catch (error) {
        logToSentry('Error tracking chatbot event:', error);
    }
};
