import request from 'utils/request';

export const fetchConversation = (funnelConversationId, data) => {
    return request(`twilio-chat-conversation/${funnelConversationId}/?api_key=${data.apiKey}`,  {
        method: 'GET'
    }).then(([body]) => body);
};

export const createConversation = (data) => {
    return request(`twilio-chat-conversation/?api_key=${data.apiKey}`, {
        method: 'POST',
        data
    }).then(([body]) => body);
};

export const updateConversation = (funnelConversationId, data) => {
    return request(`twilio-chat-conversation/${funnelConversationId}/?api_key=${data.apiKey}`,  {
        method: 'PUT',
        data
    }).then(([body]) => body);
};

export const createMessage = (funnelConversationId, data) => {
    return request(`twilio-chat-conversation/${funnelConversationId}/message/?api_key=${data.apiKey}`, {
        method: 'POST',
        data
    }).then(([body]) => body);
};

export const handoffChatBotConversationToAgent = (funnelConversationId, data, apiKey) => {
    return request(`twilio-chat-conversation/${funnelConversationId}/handoff/?api_key=${apiKey}`,  {
        method: 'PUT',
        data
    }).then(([body]) => body);
};

export const triggerResponseFromFunnelNlp = (data) => {
    return request('trigger-funnel-nlp-reply', {
        method: 'POST',
        data
    }).then(([body]) => body);
};

export const promptFunnelNlpHandoff = (data) => {
    return request('prompt-funnel-nlp',  {
        method: 'POST',
        data
    }).then(([body]) => body);
};

export const initializeFunnelNlpClient = (data) => {
    return request('initialize-quinn-client',  {
        method: 'POST',
        data
    }).then(([body]) => body);
};
