import React, { useCallback } from 'react';
import {
    unitType,
    unitsTypeType
} from 'types';
import { useChat } from 'components/Chat/ChatContext';
import { toPrice, toSquareFoot } from 'utils/formatting';
import { dateToMMMDo } from 'utils/date';
import * as unitsUtils from 'utils/unit';
import Button from 'components/Button/Button';
import styles from './Unit.scss';

const getBannerTitle = (type) => {
    if (unitsUtils.getIsNearbyListing(type)) {
        return  'Nearby Community';
    }

    if (unitsUtils.getIsSimilarListing(type)) {
        return  'Similar Listing';
    }

    return null;
};

function Unit ({ unit, type }) {
    const {
        square_footage: squareFootage,
        date_available: dateAvailable,
        unit_number: unitNumber,
        image_url: imageUrl,
        website_url: websiteUrl,
        price,
        layout
    } = unit;

    const { sendMessage } = useChat();
    const bannerTitle = getBannerTitle(type);
    const isNearbyCommunity = unitsUtils.getIsNearbyListing(type);
    const showScheduleTourButton = unitsUtils.getIsExactOrSimilarListing(type);
    const showVisitWebsiteButton = !!isNearbyCommunity && websiteUrl;

    const handleVisitWebsiteClick = useCallback(() => {
        window.open(websiteUrl, '_blank');
    }, [
        websiteUrl
    ]);

    return (
        <div
            data-testid="unit"
            className={styles.component}
        >
            {bannerTitle && (
                <div
                    className={styles.banner}
                    data-testid="unit-banner"
                >
                    {bannerTitle}
                </div>
            )}
            <div className={styles.content}>
                <div
                    data-testid="unit-image"
                    className={styles.image}
                    style={{ backgroundImage: `url(${imageUrl})` }}
                />
                <div
                    data-testid="unit-header"
                    className={styles.header}
                >
                    Unit {unitNumber}
                </div>
                <div
                    data-testid="unit-subheader"
                    className={styles.subheader}
                >
                    {layout}{squareFootage && (` | ${toSquareFoot(squareFootage)}`)}
                </div>
                <div
                    data-testid="unit-description-availability"
                    className={styles.description}
                >
                    available {dateToMMMDo(dateAvailable)}
                </div>
                <div
                    data-testid="unit-description-price"
                    className={styles.description}
                    style={{ marginBottom: 0 }}
                >
                    starting at <span className={styles.price}>{toPrice(price)}</span>
                </div>
                {showVisitWebsiteButton ? (
                    <Button
                        data-testid="unit-visit-website-button"
                        variant="solid"
                        fullWidth
                        className={styles.button}
                        onClick={handleVisitWebsiteClick}
                    >
                        Visit Website
                    </Button>
                ) : showScheduleTourButton && (
                    <Button
                        data-testid="unit-schedule-tour-button"
                        variant="solid"
                        fullWidth
                        className={styles.button}
                        onClick={() => sendMessage('Schedule Tour')}
                    >
                        Schedule Tour
                    </Button>
                )}
            </div>
        </div>
    );
}

Unit.propTypes = {
    unit: unitType,
    type: unitsTypeType
};

export default Unit;
