import React from 'react';
import PropTypes from 'prop-types';
import { useConfig } from 'components/Config/ConfigContext';
import { useChat } from 'components/Chat/ChatContext';
import { useStartChatButton } from './StartChatButtonContext';
import Fade from 'components/Fade/Fade';
import CloseSvg from 'components/Svg/CloseSvg';
import ChatSvg from 'components/Svg/ChatSvg';
import styles from './StartChatButton.scss';

function StartChatButton ({
    disable = false
}) {
    const { hasUnreadMessages } = useChat();
    const { configValues: { proactiveMessage }  } = useConfig();
    const {
        isPopupMessageVisible,
        handleOpenChat,
        handleClosePopupMessage
    } = useStartChatButton();
    const openChatHandler = !disable ? handleOpenChat : () => {};

    return (
        <div className={styles.component}>
            <Fade in={!disable && isPopupMessageVisible}>
                <div
                    data-testid="pop-up-message"
                    onClick={openChatHandler}
                    className={styles.message}
                    tabIndex="0"
                    aria-label="Check availability or schedule a tour"
                >
                    <CloseSvg
                        data-testid="pop-up-message-close"
                        className={styles.messageCloseIcon}
                        onClick={handleClosePopupMessage}
                    />
                    <span>
                        {proactiveMessage}
                    </span>
                </div>
            </Fade>

            <button
                tabIndex="0"
                data-testid="start-chat-button"
                aria-label="Start chatbot"
                onClick={openChatHandler}
                className={styles.button}
                disabled={disable}
            >
                <ChatSvg className={styles.buttonIcon} />
                <Fade in={hasUnreadMessages}>
                    <span
                        data-testid="unread-messages"
                        className={styles.unreadMessages}
                    >
                        <span className="sr-only">You have unread messages</span>
                    </span>
                </Fade>
            </button>
        </div>
    );
}

StartChatButton.propTypes = {
    disable: PropTypes.bool
};

export default StartChatButton;
