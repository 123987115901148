import React, { createElement } from 'react';
import { MESSAGE_AUTHOR, PROVIDE_VIRTUAL_TOUR_LINK_MESSAGE } from 'constants/Message';
import * as MarkupType from 'constants/MarkupType';
import { trackChatbotEvent } from './trackChatbotEvent';
import { ACTION_SEND_VIRTUAL_TOUR, EVENT_TYPE } from 'constants/Chat';

export function extractMessageParts(message) {
    const copy = { ...message };
    let payload = {};
    let body = null;

    if (copy.body) {
        body = copy.body;
        const matchOptions = copy.body.match(/(.+)?(\{.+})/s);

        if (matchOptions?.length > 2) {
            body = matchOptions[1];

            try {
                payload = {
                    ...JSON.parse(matchOptions[2])
                };
            } catch (error) {
                // ignore error
                body = copy.body;
            }
        }
    }

    if (copy.attributes) {
        payload = {
            ...payload,
            ...copy.attributes
        };

        delete copy.attributes;
    }

    return {
        ...copy,
        body,
        payload
    };
}

export function getMessageAuthorType(message) {
    if (message.author === MESSAGE_AUTHOR.SYSTEM) {
        return MESSAGE_AUTHOR.SYSTEM;
    }

    if (message.author.toLowerCase().startsWith('client')) {
        return MESSAGE_AUTHOR.USER;
    }

    return MESSAGE_AUTHOR.FUNNEL;
}

const getJsonPartsFromMessage = (body) => {
    const parts = [];
    let isFindingJSON = false;
    let amountOfOpeningBracketsBeforeFindingClosingBracket = 0;

    for (let i = 0, l = body.length; i < l; i++) {
        const char = body[i];

        if (char === '{') {
            if (!isFindingJSON) {
                isFindingJSON = true;
                parts.push({ start: i });
                continue;
            }

            amountOfOpeningBracketsBeforeFindingClosingBracket++;
            continue;
        }

        if (char === '}') {
            if (amountOfOpeningBracketsBeforeFindingClosingBracket !== 0) {
                amountOfOpeningBracketsBeforeFindingClosingBracket--;
                continue;
            }

            parts[parts.length - 1].end = i + 1;
            isFindingJSON = false;
        }
    }

    return parts;
};

const findFormattedLinks = (message) => {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;

    return [...message.matchAll(pattern)].map(match => ({
        start: match.index,
        end: match.index + match[0].length,
        linkPart: true,
        label: match[1],
        url: match[2],
    }));
};

const createFormattedLink = ({ label, url }, key, sessionId, funnelData) => {
    const handleClickLink = (event) => {
        event.preventDefault();
        trackChatbotEvent(sessionId, EVENT_TYPE.MESSAGE_LINK_CLICKED, { url, ...funnelData });
        window.open(url, '_blank');
    };

    return createElement('a', { href: url, key, target: '_blank', onClick: handleClickLink }, label);
};

const getRemainingStringPartsFromMessage = (body, jsonParts) => {
    const parts = [];
    for (let i = 0, l = jsonParts.length; i < l; i++) {
        const jsonPart = jsonParts[i];
        const nextJsonPart = (i + 1) < jsonParts.length ? jsonParts[i + 1] : null;

        if (parts.length === 0 && jsonPart.start !== 0) {
            parts.push({ start: 0, end: jsonPart.start });
        }

        if (nextJsonPart) {
            parts.push({ start: jsonPart.end, end: nextJsonPart.start });
        } else {
            parts.push({ start: jsonPart.end, end: body.length });
        }
    }

    return parts;
};

const messagesPartsSorter = (a, b) => {
    if (a.start < b.start) {
        return -1;
    }

    if (a.start > b.start) {
        return 1;
    }

    return 0;
};

const getReactElementByMarkupType = (type) => {
    if (type === MarkupType.LINK) {
        return 'a';
    }

    if (type === MarkupType.BOLD) {
        return 'strong';
    }

    if (type === MarkupType.ITALIC) {
        return 'em';
    }
};

export const formatMessageBodyMarkup = (body, sessionId, funnelData) => {
    if (!body) return null;

    let specialParts = [];
    const formattedLinks = findFormattedLinks(body);
    if (formattedLinks.length !== 0)
        specialParts = specialParts.concat(formattedLinks);

    specialParts.push(...getJsonPartsFromMessage(body));
    const stringParts = getRemainingStringPartsFromMessage(body, specialParts);
    const messageParts = [...specialParts, ...stringParts];
    messageParts.sort(messagesPartsSorter);

    if (messageParts.length === 0) {
        return body;
    }

    return messageParts.map((messagePart, i) => {
        let reactElement;

        try {
            const json = JSON.parse(body.substring(messagePart.start, messagePart.end));
            const elementType = getReactElementByMarkupType(json.type);
            reactElement = createElement(
                elementType,
                {
                    key: i,
                    ...json.props
                },
                json.text
            );
        } catch (_e) {
            if (messagePart.linkPart === true) {
                reactElement = createFormattedLink(messagePart, i, sessionId, funnelData);
            } else {
                reactElement = (
                    <span key={i}>{body.substring(messagePart.start, messagePart.end)}</span>
                );
            }
        }

        return reactElement;
    });
};

export const formatVirtualTourMessage = (url) => {
    return {
        ...PROVIDE_VIRTUAL_TOUR_LINK_MESSAGE,
        attributes: {
            ...PROVIDE_VIRTUAL_TOUR_LINK_MESSAGE.attributes,
            tour_url: url
        }
    };
};

export const extractTourUrlFromActions = (actions) => {
    const action = actions.find(a => a.startsWith(ACTION_SEND_VIRTUAL_TOUR));
    if (!action) return null;
    
    const [, url] = action.split(/:(.+)/);
    if (!url || !url.trim() || !isValidUrl(url.trim())) return null;
    return url.trim();
};

const isValidUrl = (urlString) => {
    try {
        new URL(urlString);
        return true;
    } catch (_) {
        return false;
    }
};
