const BREAK_POINTS = {
    PHONE: 320,
    TABLET: 768,
    DESKTOP: 1024,
};

function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function getDimensionValues () {
    const width = window.parent.document.documentElement.clientWidth;
    const height = window.parent.document.documentElement.clientHeight;
    const dimensions = {
        width: width,
        height: height
    };

    let isDesktop;
    let isTablet;
    let isMobile;

    if (width >= BREAK_POINTS.DESKTOP) {
        isDesktop = true;
        isTablet = false;
        isMobile = false;
    } else if (width >= BREAK_POINTS.TABLET) {
        isDesktop = false;
        isTablet = true;
        isMobile = false;
    } else {
        isDesktop = false;
        isTablet = false;
        isMobile = true;
    }

    return {
        width,
        height,
        dimensions,
        isDesktop,
        isTablet,
        isMobile
    }
}

/**
 * This message handler will perform certain actions based on the incoming message 'action type'.
 *
 *  - type: INITIALIZE:
 *      It will attach a window resize handler (debounced) that will calculate
 *      the dimension values and sends them to the chat bot.
 */
function messageHandler (event) {
    let data;
    try {
        data = JSON.parse(event.data);
    } catch (e) {
        // ignore
        return;
    }

    if (data.type === 'INITIALIZE') {
        const handleWindowResize = debounce(function () {
            const payload = getDimensionValues();

            const message = {
                type: 'WINDOW_RESIZED',
                payload: payload
            };

            event.source.postMessage(JSON.stringify(message), event.origin);
        }, 300);

        window.addEventListener('resize', handleWindowResize);

        handleWindowResize();
        // Note: call handle window resize once so that the chat bot knows the initial dimensions
    }
    // Add logic here to perform other actions
}

window.addEventListener("message", messageHandler);
