import React, { useLayoutEffect, useState } from 'react';
import cx from 'classnames';
import { KEYBOARD_INPUT, useKeyListener } from 'hooks/useKeyListener';
import { useFunnelApi } from 'components/Chat/FunnelApiContext';
import { useConfig } from 'components/Config/ConfigContext';
import { useParentWindowCommunicator } from 'components/ParentWindowCommunicator/ParentWindowCommunicator';
import { useChat } from 'components/Chat/ChatContext';
import Chat from 'components/Chat/Chat';
import StartChatButton from 'components/StartChatButton/StartChatButton';
import styles from './App.scss';
import ReactGA from 'react-ga4';

if (window.GOOGLE_ANALYTICS_TRACKING_ID){
    ReactGA.initialize(window.GOOGLE_ANALYTICS_TRACKING_ID);
}

function App () {
    const { configurationObtained } = useConfig();
    const { funnelData } = useFunnelApi();
    const { isDesktop, disableChat } = useParentWindowCommunicator();
    const { isOpen } = useChat();
    const isFullScreen = !isDesktop && isOpen;
    const [hideOutline, setHideOutline] = useState(true);

    useLayoutEffect(() => {
        funnelData.leadSourceFromDni = window._fnlLeadSource;
        funnelData.campaignInfo = window._fnlCampaignInfo;
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [window._fnlLeadSource, window._fnlCampaignInfo]);
    /* eslint-enable react-hooks/exhaustive-deps */

    useKeyListener(KEYBOARD_INPUT.TAB, () => {
        setHideOutline(false);
    });

    if (disableChat) {
        return (
            <div className={cx(styles.component, styles.hideOutline)}>
                <StartChatButton disable />
            </div>
        );
    }

    return (
        <div
            className={cx(styles.component, {
                [styles.fullScreen]: isFullScreen,
                [styles.hideOutline]: hideOutline
            })}
        >
            {configurationObtained && isOpen && (
                <Chat className={styles.chat} />
            )}
            {!isOpen && (
                <StartChatButton />
            )}
        </div>
    );
}

export default App;
