import React, {
    useRef,
    useState,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import Fade from 'components/Fade/Fade';
import Button from 'components/Button/Button';
import ArrowDown from 'components/Svg/ArrowDownSvg';
import styles from './MessageList.scss';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';
import { EVENT_TYPE } from 'constants/Chat';

export const MAX_NR_OF_INITIAL_ITEMS = 7;

function MessageList ({
    items,
    sessionId,
    funnelData,
}) {
    const listRef = useRef();
    const [isExpanded, setIsExpanded] = useState(false);
    const initialItems = items.slice(0, MAX_NR_OF_INITIAL_ITEMS);
    const remaingItems = items.slice(MAX_NR_OF_INITIAL_ITEMS, items.length);

    const handleExpandClick = useCallback(() => {
        setIsExpanded(true);
        trackChatbotEvent(sessionId, EVENT_TYPE.LIST_SHOW_MORE_CLICK, funnelData);
        // Note: scroll to bottom of list AFTER Fade entering animation has finished
        setTimeout(() => {
            listRef.current.scrollIntoView();
        }, 300);
    }, [funnelData, sessionId]);

    const renderItems = useCallback((itemsToRender) => {
        return itemsToRender.map((item, index) => {
            return (
                <li
                    key={index}
                    className={styles.listItem}
                >
                    {item}
                </li>
            );
        });
    }, []);

    return (
        <ul
            ref={listRef}
            className={styles.list}
        >
            {renderItems(initialItems)}

            {remaingItems.length > 0 && !isExpanded && (
                <>
                    <div className={styles.listOverlay} />
                    {!isExpanded && (
                        <Button
                            data-testid="message-list-show-more"
                            variant="clear"
                            onClick={handleExpandClick}
                        >
                            Show More
                            <ArrowDown />
                        </Button>
                    )}
                </>
            )}

            {remaingItems.length > 0 && (
                <Fade in={isExpanded}>
                    {renderItems(remaingItems)}
                </Fade>
            )}
        </ul>
    );
}

MessageList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    sessionId: PropTypes.string,
    funnelData: PropTypes.object,
};

export default MessageList;
