import React, {
    useRef,
    useState,
    useMemo,
    useCallback,
    createContext,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import { random } from 'lodash';
import moment from 'moment';
import * as ConfigAPI from 'api/configuration';
import * as ConversationAPI from 'api/conversation';
import { isDev, isProd } from 'utils/env';
import { playAudioFile } from 'utils/audio';
import { getPerson } from 'utils/clients';
import { URL } from 'config';
import { useConfig } from 'components/Config/ConfigContext';
import { TwilioChatClient } from 'components/Chat/TwilioChatClient';
import {
    MESSAGE_AUTHOR,
    INITIAL_FUNNEL_NLP_MESSAGES,
    REQUEST_PII_FOR_TOUR_INTRO_MESSAGE,
    REQUEST_PII_FOR_EMAIL_INTRO_MESSAGE,
    REQUEST_PII_FOR_TEXT_INTRO_MESSAGE,
    REQUEST_PII_FOR_PHONE_INTRO_MESSAGE,
    PROVIDE_VIRTUAL_TOUR_LINK_MESSAGE
} from 'constants/Message';
import {
    CHAT_STATE,
    CHAT_TYPE,
    CHAT_MODE,
    LIVE_AGENT_STATUS,
    ACTION_EMAIL,
    ACTION_TEXT,
    ACTION_TOUR,
    ACTION_PHONE,
    ACTION_SEND_VIRTUAL_TOUR,
    REQUEST_CONTACT,
    REQUEST_TOUR,
    REQUEST_CONTACT_TYPES,
    EVENT_TYPE,
} from 'constants/Chat';
import {
    extractMessageParts,
    extractTourUrlFromActions,
    formatVirtualTourMessage,
    getMessageAuthorType
} from 'utils/message';
import { useFunnelApi } from 'components/Chat/FunnelApiContext';
import { useError } from 'components/ErrorContext/ErrorContext';
import { useChatStorage } from 'components/ChatStorage/ChatStorageContext';
import { v4 as uuidv4 } from 'uuid';
import { trackChatbotEvent } from 'utils/trackChatbotEvent';

const createRandomName = () => {
    return `FUNNEL-${random(11111111, 99999999)}`;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const ChatContext = createContext({});

export const useChat = () => useContext(ChatContext);

const messageToDelay = 'is there anything else i can help you with?';

const getMessageDelay = (message) => {
    if (message.body && messageToDelay === message.body.toLowerCase()) {
        return 3000;
    }

    return 1000;
};

const MESSAGE_QUEUE_LOOP_DELAY = 10;
export const POP_HAND_RAISE_DELAY = isProd ? 20000 : 1000;

export const messageReceivedSound = new Audio(`${URL}/public/audio/messageReceived.m4a`);
export const messageSentSound = new Audio(`${URL}/public/audio/messageSent.m4a`);

// There is a compatibility issue between hooks and Twilio events - reading a state variable
// from a Twilio event callback does not work. As a workaround some variables are (also) defined here.
let currentLiveAgent = null;

export function ChatProvider ({ children, apiKey, communityId, leadSourceId }) {
    const [didSendInitialAttributes, setDidSendInitAttr] = useState(false);
    const messageQueue = useRef([]);
    const [channel, setChannel] = useState(null);
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [chatState, setChatState] = useState(CHAT_STATE.DISCONNECTED);
    const [chatMode, setChatMode] = useState(null);
    const [client, setClient] = useState(null);
    const [showLiveChatForm, setShowLiveChatForm] = useState(false);
    const [showChatBotInformationForm, setShowChatBotInformationForm] = useState(false);
    const [sessionId, setSessionId] = useState(null);

    const channelName = createRandomName();
    const {
        configValues: {
            employeeGroupId,
            name: communityName,
            chatType,
            introMessage,
        }
    } = useConfig();

    const { error, handleError } = useError();

    const twilioChatClient = new TwilioChatClient({
        events: {
            errorOccurred: handleError,
            messageAdded: (message) => {
                displayMessage(message);
            },
            memberJoined: (member) => {
                addLiveAgent(member);
            },
            channelRemoved: (channelToRemove) => {
                handleChannelRemoved(channelToRemove);
            }
        }
    });

    const funnelApi = useFunnelApi();
    const { funnelData } = funnelApi;
    const chatStorage = useChatStorage();

    const eitherLeadSourceId = leadSourceId || funnelData.leadSourceFromDni;

    const processMessageQueue = useCallback(async() => {
        while (messageQueue.current.length) {
            const [next] = messageQueue.current;
            if (next) {
                setAwaitingResponse(true);
                messageQueue.current = messageQueue.current.slice(1);
                await delay(next.delay);
                setMessages(currentMessages => [...currentMessages, next.message]);
                !isOpen && setHasUnreadMessages(true);
                setAwaitingResponse(false);
                !isDev && playAudioFile(messageReceivedSound);
            }

            await delay(MESSAGE_QUEUE_LOOP_DELAY);
        };
    }, [isOpen]);

    const addLiveAgent = useCallback(async (member) => {
        if (member.identity.startsWith('Client')) {
            return;
        }

        const agent = {
            name: member.identity,
            status: LIVE_AGENT_STATUS.CONNECTED
        };

        currentLiveAgent = { ...agent };
        setChatState(CHAT_STATE.CONNECTED);

        setMessages(currentMessages => {
            const lastMessage = currentMessages.pop();
            if (!lastMessage) {
                return [];
            }
            lastMessage.payload = {
                ...lastMessage.payload,
                liveAgent: agent,
                hasLiveAgentData: true
            };

            chatStorage.updateLastMessage(lastMessage);

            return [...currentMessages, lastMessage];
        });

        chatStorage.saveChat({ currentLiveAgent });
    }, [
        chatStorage
    ]);

    const displayChannelHistory = useCallback(async () => {
        let storedMessages = chatStorage.getMessages();

        if (!storedMessages || !storedMessages.length) {
            return;
        }

        storedMessages = storedMessages.map((message, index) => {
            if (!message.payload) {
                message.payload = {};
            }

            if (index !== storedMessages.length - 1) {
                message.payload.selected = true;

                // Remove waiting bubbles except the last one
                if (message.payload?.hasLiveAgentData && !message.payload?.liveAgent) {
                    message.payload.hasLiveAgentData = false;
                }
            }

            return message;
        });

        setMessages(storedMessages);
    }, [
        chatStorage,
        setMessages
    ]);

    /**
     * This callback is used by Twilio events package that has compatibility issues
     * with hooks. This causes an issue when trying to read data from state (data is always null),
     * Avoid using state variables for data that we have to read here.
     */
    const displayMessage = useCallback(async (message) => {
        message = extractMessageParts(message.state || message);
        if (message?.payload?.isDisplayed) {
            return;
        }

        const authorType = getMessageAuthorType(message);
        if (authorType === MESSAGE_AUTHOR.FUNNEL) {
            message.payload = {
                liveAgent: currentLiveAgent,
                ...message.payload
            };
        }

        if (authorType === MESSAGE_AUTHOR.SYSTEM) {
            const newQueueObj = {
                delay: getMessageDelay(message),
                message
            };
            messageQueue.current = messageQueue.current.concat([newQueueObj]);
            processMessageQueue();
        } else {
            setAwaitingResponse(true);
            setMessages(currentMessages => {
                const lastMessage = currentMessages.pop();
                if (lastMessage?.payload?.options) {
                    lastMessage.payload.selected = message.body;
                }

                if (lastMessage) {
                    return [...currentMessages, lastMessage, message];
                }

                return [...currentMessages, message];
            });

            if (authorType === MESSAGE_AUTHOR.USER) {
                !isDev && playAudioFile(messageSentSound);
            } else {
                !isDev && playAudioFile(messageReceivedSound);
            }
        }

        await funnelApi.createMessage(message);
        chatStorage.saveMessages(message);

        if (message?.payload?.client_id) {
            await funnelApi.updateConversation(message.payload.client_id);
        }

        return message;
    }, [funnelApi, chatStorage, processMessageQueue]);

    const sendMessage = useCallback(async (message, attributes = {}, button = '') => {
        const payload = didSendInitialAttributes ? attributes : {
            ...attributes,
            apiKey,
            communityId,
            employeeGroupId,
            chatType,
            leadSourceId: eitherLeadSourceId,
            campaignInfo: funnelData.campaignInfo
        };

        await channel?.sendMessage(message, payload);
        setDidSendInitAttr(true);

        if (chatMode !== CHAT_MODE.CHATBOT) {
            trackChatbotEvent(sessionId, EVENT_TYPE.LIVE_CHAT_MESSAGE_SENT, { ...funnelData, message: message });
            return;
        }

        ConversationAPI.triggerResponseFromFunnelNlp({
            message: message,
            group_id: employeeGroupId,
            community_id: communityId,
            api_key: apiKey,
            chat_id: funnelData.conversationId,
            channel_sid: channel.sid,
            button: button,
        }).then((data) => {
            const { intents = [], entities = [], actions = [], tasks_completed = [] } = data;

            trackChatbotEvent(sessionId, EVENT_TYPE.CHATBOT_MESSAGE_SENT, { ...funnelData, message: message, intents, entities, tasks_completed });

            if (actions.includes(ACTION_EMAIL)) { displayMessage(REQUEST_PII_FOR_EMAIL_INTRO_MESSAGE); }
            else if (actions.includes(ACTION_TEXT)) { displayMessage(REQUEST_PII_FOR_TEXT_INTRO_MESSAGE); }
            else if (actions.includes(ACTION_TOUR)) { displayMessage(REQUEST_PII_FOR_TOUR_INTRO_MESSAGE); }
            else if (actions.includes(ACTION_PHONE)) { displayMessage(REQUEST_PII_FOR_PHONE_INTRO_MESSAGE); }
            else if (actions.some(action => action.startsWith(ACTION_SEND_VIRTUAL_TOUR))) {
                const url = extractTourUrlFromActions(actions);
                if (!url) return null;
                displayMessage(formatVirtualTourMessage(url));
            }
        });

    }, [didSendInitialAttributes, apiKey, communityId, employeeGroupId, chatType, eitherLeadSourceId, funnelData, channel, chatMode, sessionId, displayMessage]);

    const funnelNlpChatIntro = useCallback(async() => {
        const greetingResponse = await ConfigAPI.getChatbotGreeting(communityId, apiKey);

        if (greetingResponse.greeting) {
            for (let i = 0; i < greetingResponse.greeting.length; i++) {
                const messageData = {
                    author: MESSAGE_AUTHOR.SYSTEM,
                    body: greetingResponse.greeting[i],
                };

                // only add buttons to last message
                if (greetingResponse.greeting.length - 1 === i) {
                    if (greetingResponse.buttons) {
                        messageData.attributes = { options: greetingResponse.buttons };
                    } else {
                        messageData.attributes = INITIAL_FUNNEL_NLP_MESSAGES[0].attributes;
                    }
                }

                displayMessage(messageData);

                // delay between each message
                if (greetingResponse.greeting.length - 1 !== i)
                    await new Promise(resolve => setTimeout(resolve, greetingResponse.delay_ms));
            }
        } else {
            displayMessage(INITIAL_FUNNEL_NLP_MESSAGES[0]);
        }
    }, [communityId, apiKey, displayMessage]);

    const createChannel = useCallback(async () => {
        await twilioChatClient.initializeChat();
        const newChannel = await twilioChatClient.createChannel(channelName);

        await twilioChatClient.joinChannel(newChannel);
        await setChannel(newChannel);

        return newChannel;
    }, [
        channelName,
        twilioChatClient
    ]);

    const initializeFunnelNlpChat = useCallback(async () => {
        chatStorage.setStorageType(CHAT_MODE.CHATBOT);
        setChatMode(CHAT_MODE.CHATBOT);

        try {
            setChatState(CHAT_STATE.INITIALISING);
            const newChannel = await createChannel();

            await funnelNlpChatIntro();

            setChatState(CHAT_STATE.CONNECTED);
            await funnelApi.createConversation(newChannel.sid, CHAT_MODE.CHATBOT);

            chatStorage.saveChat({
                conversationId: funnelData.conversationId,
                token: twilioChatClient.token
            });

        } catch (e) {
            handleError(e);
        }

    }, [chatStorage, createChannel, funnelApi, funnelData.conversationId, twilioChatClient.token, funnelNlpChatIntro, handleError]);

    const restoreAutomatedChatSession = useCallback(async (session) => {
        funnelData.conversationId = session.conversationId;
        funnelData.conversationType = CHAT_MODE.CHATBOT;

        chatStorage.setStorageType(CHAT_MODE.CHATBOT);
        setChatMode(CHAT_MODE.CHATBOT);
        setChatState(CHAT_STATE.INITIALISING);

        try {
            displayChannelHistory();
            await twilioChatClient.initializeChat(session.token);
            const newChannel = await twilioChatClient.getChannel(session.channelSid);
            twilioChatClient.subscribeToChannelEvents(newChannel);
            await setChannel(newChannel);
            setChatState(CHAT_STATE.CONNECTED);
        } catch (e) {
           handleError(e);
        }
    }, [
        funnelData.conversationId,
        funnelData.conversationType,
        chatStorage,
        displayChannelHistory,
        twilioChatClient,
        handleError
    ]);

    const restoreLiveChatSession = useCallback(async (session, isFormSubmit) => {
        funnelData.conversationId = session.conversationId;
        funnelData.conversationType = CHAT_MODE.LIVE_CHAT;

        setClient({
            fullname: session.client.fullName,
            email: session.client.email
        });

        chatStorage.setStorageType(CHAT_MODE.LIVE_CHAT);
        setChatMode(CHAT_MODE.LIVE_CHAT);
        setChatState(CHAT_STATE.INITIALISING);

        try {
            currentLiveAgent = session.currentLiveAgent;
            displayChannelHistory();

            await twilioChatClient.initializeChat(session.token);
            const newChannel = await twilioChatClient.getChannel(session.channelSid);

            if (!session.token) {
                await twilioChatClient.joinChannel(newChannel);
            } else {
                twilioChatClient.subscribeToChannelEvents(newChannel);
            }

            await setChannel(newChannel);

            // Check if an agent has already joined
            if (isFormSubmit) {
                const members = await newChannel.getMembers();
                const agent = members.filter(member => getMessageAuthorType({ author: member.identity }) === MESSAGE_AUTHOR.FUNNEL);
                if (agent.length > 0) {
                    addLiveAgent(agent[0]);
                }
            }

            if (currentLiveAgent) {
                setChatState(CHAT_STATE.CONNECTED);
            }

        } catch (e) {
           handleError(e);
        }

    }, [
        funnelData.conversationId,
        funnelData.conversationType,
        chatStorage,
        displayChannelHistory,
        twilioChatClient,
        addLiveAgent,
        handleError
    ]);

    const handleRefreshChat = useCallback(async () => {
        chatStorage.clear(CHAT_MODE.LIVE_CHAT);
        chatStorage.clear(CHAT_MODE.CHATBOT);
        trackChatbotEvent(sessionId, EVENT_TYPE.REFRESH_CHAT, funnelData);

        FunnelChat.restart();
    }, [chatStorage, funnelData, sessionId]);

    const getPreviousSessionFromStorage = useCallback(async (type) => {
        const chatData = chatStorage.getChat(type);
        if (!chatData) {
            return false;
        }

        let previousSession;
        try {
            previousSession = await ConversationAPI.fetchConversation(chatData.conversationId,{ apiKey });
        } catch (e) {
            return false;
        }

        if (!previousSession || !previousSession.is_active) {
            return false;
        }

        return {
            channelSid: previousSession.channel_sid,
            ...previousSession,
            ...chatData
        };

    }, [
        apiKey,
        chatStorage
    ]);

    useEffect(() => {
        const existingSessionId = chatStorage.getSessionId();
        const currentSessionId = existingSessionId ? existingSessionId : uuidv4();
        setSessionId(currentSessionId);
        if (!existingSessionId) {
            chatStorage.saveSessionId(currentSessionId);
        }
    }, [chatStorage]);

    const openChat = useCallback(async () => {
        clearTimeout();
        setHasUnreadMessages(false);
        setIsOpen(true);
        trackChatbotEvent(sessionId, EVENT_TYPE.OPEN_CHATBOT, funnelData);

        if (chatState !== CHAT_STATE.DISCONNECTED) {
            return;
        }

        const chatTypeToPreviousSession = {
            [CHAT_TYPE.LIVE_ONLY]: async () => await getPreviousSessionFromStorage(CHAT_MODE.LIVE_CHAT),
            [CHAT_TYPE.AUTOMATED_ONLY]: async () => await getPreviousSessionFromStorage(CHAT_MODE.CHATBOT),
            [CHAT_TYPE.LIVE_AND_AUTOMATED]: async () => {
                return await getPreviousSessionFromStorage(CHAT_MODE.LIVE_CHAT) || await getPreviousSessionFromStorage(CHAT_MODE.CHATBOT);
            }
        };

        const previousSession = await chatTypeToPreviousSession[chatType]();

        const expiresIn = previousSession ? chatStorage.getChat(previousSession.conversation_type).expiresIn : null;
        const isExpired = expiresIn ? moment(expiresIn).isBefore(moment.now()) : false;

        if (!previousSession || isExpired) {
            chatStorage.clear(CHAT_MODE.LIVE_CHAT, CHAT_MODE.CHATBOT);

            chatType === CHAT_TYPE.LIVE_ONLY ? setShowLiveChatForm(true) : await initializeFunnelNlpChat();
        } else {
            previousSession.conversation_type === CHAT_MODE.CHATBOT ?
                await restoreAutomatedChatSession(previousSession) :
                await restoreLiveChatSession(previousSession);
        }
    }, [sessionId, funnelData, chatState, chatType, chatStorage, getPreviousSessionFromStorage, initializeFunnelNlpChat, restoreAutomatedChatSession, restoreLiveChatSession]);


    const closeChat = useCallback(() => {
        setIsOpen(false);
        trackChatbotEvent(sessionId, EVENT_TYPE.CLOSE_CHATBOT, funnelData);
    }, [funnelData, sessionId]);

    const handleChannelRemoved = useCallback(async (channelToRemove) => {
        setChatState(CHAT_STATE.DISCONNECTED);

        currentLiveAgent = {
            ...currentLiveAgent,
            status: LIVE_AGENT_STATUS.DISCONNECTED
        };

        setMessages(currentMessages => {
            const message = { ...currentMessages.pop() };
            return [...currentMessages, {
                ...message,
                payload: {
                    hasLiveAgentData: true,
                    channelSid: channelToRemove.sid,
                    liveAgent: currentLiveAgent
                }
            }];
        });

        chatStorage.clear(CHAT_MODE.LIVE_CHAT);
    }, [
        chatStorage
    ]);

    const submitChatbotInformationForm = useCallback(async (clientData) => {
        try {
            const { requestType, ...data } = clientData;
            const response = await funnelApi.collectClientPII({ ...data, leadSourceId: eitherLeadSourceId });
            const person = getPerson(response.client);
            if (!person) {
                handleError(new Error(`Client (id=${response.client.id}) has no people associated with it.`));
                return;
            }
            let prompt = REQUEST_TOUR;
            await funnelApi.updateConversation(response.client.id);
            await ConversationAPI.initializeFunnelNlpClient({
                chat_id: funnelData.conversationId,
                client_id: response.client.id,
                group_id: employeeGroupId,
                community_id: communityId,
                person_id: person.id
            });
            if (REQUEST_CONTACT_TYPES.includes(requestType)) {
                await ConversationAPI.handoffChatBotConversationToAgent(funnelData.conversationId, { request: requestType }, apiKey);
                prompt = REQUEST_CONTACT; // quinn expects prompt to be request_contact or request_tour
            }
            await ConversationAPI.promptFunnelNlpHandoff({
                prompt: prompt,
                group_id: employeeGroupId,
                community_id: communityId,
                api_key: apiKey,
                chat_id: funnelData.conversationId,
                channel_sid: channel?.sid
            });
            trackChatbotEvent(sessionId, EVENT_TYPE.FORM_SUBMIT, { ...funnelData, requestType });
            setShowChatBotInformationForm(false);

        } catch (e) {
            handleError(e);
        }
    }, [funnelApi, eitherLeadSourceId, funnelData, employeeGroupId, communityId, channel, apiKey, sessionId, handleError]);

    const initializeLiveChat = useCallback(async (clientData, isFormSubmit=true) => {
        chatStorage.clear(CHAT_MODE.LIVE_CHAT);
        chatStorage.clear(CHAT_MODE.CHATBOT);
        const liveChatClient = client || clientData;
        setClient(liveChatClient);

        let message = {
            author: 'Client_' + liveChatClient.fullName,
            body: clientData.message,
            attributes: {
                hasLiveAgentData: true,
            }
        };

        currentLiveAgent = null;

        setChatMode(CHAT_MODE.LIVE_CHAT);
        chatStorage.setStorageType(CHAT_MODE.LIVE_CHAT);
        setChatState(CHAT_STATE.INITIALISING);
        setShowLiveChatForm(false);

        message = await displayMessage(message);

        const existingClientId = funnelData.clientId;

        try {
            if (isFormSubmit) {
                const response = await funnelApi.createClient({ ...clientData, leadSourceId: eitherLeadSourceId });
                const activeSessionId = response.client.active_live_chat_id;
                if (activeSessionId) {
                    const previousSession = await ConversationAPI.fetchConversation(
                        activeSessionId,{ apiKey }
                    );

                    if (previousSession) {
                        chatStorage.saveChat({
                            conversationId: activeSessionId,
                            client: {
                                fullname: liveChatClient.fullName,
                                email: liveChatClient.email
                            }
                        });

                        return restoreLiveChatSession({
                            conversationId: activeSessionId,
                            channelSid: previousSession.channel_sid,
                            client: liveChatClient,
                            ...previousSession
                        }, isFormSubmit);
                    }
                }
            }

            const newChannel = await createChannel();

            message.payload.hasLiveAgentData = true;
            message.payload.channelSid = newChannel.sid;
            message.payload.liveAgent = null;

            setMessages(currentMessages => {
                currentMessages.pop();
                return [...currentMessages, message];
            });

            chatStorage.updateLastMessage(message);

            await newChannel.sendMessage(message.body, { ...message.payload, isDisplayed: true });

            // Figure out if there was a previous conversation but it never had client data set
            const previousConversationNeedClientData = funnelData.conversationId && !existingClientId;

            // Update previous conversation with the client id
            if (previousConversationNeedClientData && funnelData.clientId) {
                await funnelApi.updateConversation(funnelData.clientId);
            }

            await funnelApi.createConversation(newChannel.sid, CHAT_MODE.LIVE_CHAT);
            chatStorage.saveChat({
                conversationId: funnelData.conversationId,
                client: {
                    fullname: liveChatClient.fullName,
                    email: liveChatClient.email
                },
                token: twilioChatClient.token,
            });
        } catch (e) {
            handleError(e);
        }

    }, [chatStorage, client, displayMessage, funnelData.clientId, funnelData.conversationId, createChannel, funnelApi, twilioChatClient.token, eitherLeadSourceId, apiKey, restoreLiveChatSession, handleError]);

    const contextValue = useMemo(() => ({
        initializeFunnelNlpChat,
        funnelNlpChatIntro,
        sendMessage,
        messages,
        awaitingResponse,
        channel,
        displayMessage,
        isOpen,
        handleRefreshChat,
        hasUnreadMessages,
        openChat,
        closeChat,
        initializeLiveChat,
        submitChatbotInformationForm,
        chatState,
        chatMode,
        showLiveChatForm,
        setShowLiveChatForm,
        showChatBotInformationForm,
        setShowChatBotInformationForm,
        displayChannelHistory,
        handleError,
        funnelData,
        error,
        sessionId,
    }), [initializeFunnelNlpChat, funnelNlpChatIntro, sendMessage, messages, awaitingResponse, channel, displayMessage, isOpen, handleRefreshChat, hasUnreadMessages, openChat, closeChat, initializeLiveChat, submitChatbotInformationForm, chatState, chatMode, showLiveChatForm, showChatBotInformationForm, displayChannelHistory, handleError, funnelData, error, sessionId]);

    return (
        <ChatContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </ChatContext.Provider>
    );
}

ChatProvider.propTypes = {
    apiKey: PropTypes.string,
    communityId: PropTypes.number,
    children: PropTypes.any.isRequired,
    leadSourceId: PropTypes.number,
};
