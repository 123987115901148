import React from 'react';

export default function ArrowLeftSvg(props) {
    return (
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
            <path d="M0 0h24v24H0V0z" fill="none"/>
        </svg>
    );
}
