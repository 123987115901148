import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Iframe from './Iframe';
import Fade from 'components/Fade/Fade';
import ChatSvg from 'components/Svg/ChatSvg';
import { logToSentry } from 'utils/sentry';
import styles from './ErrorBoundary.scss';

const initialState = {
    hasError: false,
    showError: false
};

export default class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    static getDerivedStateFromError () {
        return { hasError: true };
    }

    state = initialState;

    componentDidCatch (error) {
        logToSentry(error);
    };

    showError = () => {
        this.setState({ showError: true });
    };

    hideError = () => {
        this.setState({ showError: false });
    };

    render () {
        const { children } = this.props;
        const { hasError, showError } = this.state;

        if (hasError) {
            return (
                <Iframe tooltipVisible={showError}>
                    <div className={styles.component}>
                        <div className={styles.content}>
                            <Fade in={showError}>
                                <div
                                    className={styles.message}
                                    aria-label="Sorry but chat is currently unavailable. Please try back in a bit!"
                                    tabIndex="0"
                                >
                                    Sorry but chat is <br/>
                                    currently unavailable. <br/>
                                    Please try back in a bit!
                                </div>
                            </Fade>

                            <button
                                className={styles.button}
                                onMouseEnter={this.showError}
                                onMouseLeave={this.hideError}
                                aria-label="Chatbot unavailable"
                                tabIndex="0"
                            >
                                <ChatSvg className={styles.buttonIcon} />
                            </button>
                        </div>
                    </div>
                </Iframe>
            );
        }

        return children;
    }
}
