import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import cx from 'classnames';
import styles from './Fade.scss';

function Fade ({
    in: inProp,
    duration,
    children
}) {
    return (
        <Transition
            in={inProp}
            timeout={duration}
        >
            {state => (
                <div
                    className={cx(styles.component, {
                        [styles[`component--${state}`]]: state
                    })}
                    data-testid="fade-transition"
                >
                    {children}
                </div>
            )}
        </Transition>
    );
}

Fade.propTypes = {
    in: PropTypes.bool,
    duration: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired
};

Fade.defaultProps = {
    duration: 300
};

export default Fade;
