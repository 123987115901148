import React, {
    useState,
    useCallback,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { messagePayloadType } from 'types';
import * as OptionActionTypes from 'constants/OptionActionTypes';
import * as OptionLabelTypes from 'constants/OptionLabelTypes';
import {
    MESSAGE_QUESTION,
    MESSAGE_OPTIONS_LAYOUT_TYPE,
    MESSAGE_OPTION_CHAT_WITH_AGENT
} from 'constants/Message';
import * as dateUtils from 'utils/date';
import { useChat } from 'components/Chat/ChatContext';
import useAutoFocus from 'hooks/useAutoFocus';
import Button from 'components/Button/Button';
import styles from './MessageOptions.scss';

const OPTIONS_LIMIT = 6;

function MessageOptions({ payload, scrollToBottom, residentRedirectUrl }) {
    const {
        selected,
        question,
        more_options: extraOptions,
        options_layout_type: layoutType,
        layout_header: layoutHeader,
        layout_sub_header: layoutSubHeader,
        options_multi_select: optionsMultiSelect,
    } = payload;

    const optionsContainer = useRef(null);
    const { sendMessage, setShowLiveChatForm, sessionId, funnelData } = useChat();
    const [displayAllOptions, setDisplayAllOptions] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const options = !showMoreOptions ? payload.options : extraOptions;
    const displayMoreButtons = !displayAllOptions && options.length > OPTIONS_LIMIT;
    const moreButtonLabel = !showMoreOptions ? 'See More' : 'More Options';
    const isOverlay = layoutType === MESSAGE_OPTIONS_LAYOUT_TYPE.OVERLAY;
    const displayedOptions = displayMoreButtons
        ? options.slice(0, OPTIONS_LIMIT - 1)
        : options;

    useAutoFocus(optionsContainer);

    const getOptionLabel = useCallback((option) => {
        if (!question) {
            return option;
        }

        if (question === MESSAGE_QUESTION.APPOINTMENT_DATE) {
            return dateUtils.dateTodddMMMDo(option);
        }

        if (question === MESSAGE_QUESTION.APPOINTMENT_TIME) {
            return dateUtils.timeTohhmma(option);
        }

        return option;
    }, [
        question
    ]);

    const getFormattedMessage = useCallback((option) => {
        if (!question) {
            return option;
        }

        if (question === MESSAGE_QUESTION.APPOINTMENT_LAYOUT_PREFERENCES) {
            return selectedOptions.join(', ');
        }

        return option;
    }, [
        question,
        selectedOptions
    ]);

    const sendFormattedMessage = useCallback((option = null) => {
        const message = getFormattedMessage(option);

        if (option === MESSAGE_OPTION_CHAT_WITH_AGENT) {
            setShowLiveChatForm(true);
        } else {
            sendMessage(message, {}, message);
        }
    }, [
        getFormattedMessage,
        sendMessage,
        setShowLiveChatForm
    ]);

    const handleOptionClick = useCallback((option) => () => {
        if (!optionsMultiSelect) {
            return sendFormattedMessage(option);
        }

        const indexSelected = selectedOptions.indexOf(option);
        let newSelectedOptions = [...selectedOptions];
        if (indexSelected === -1) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions = newSelectedOptions.filter(v => v !== option);
        }

        setSelectedOptions(newSelectedOptions);
    }, [
        optionsMultiSelect,
        selectedOptions,
        sendFormattedMessage
    ]);

    const handleEnhancedOptionClick = useCallback((option) => () => {
        const { action } = option;

        if (action === OptionActionTypes.SHOW_MORE_OPTIONS) {
            setDisplayAllOptions(false);
            setShowMoreOptions(true);
            scrollToBottom();
        }
    }, [setDisplayAllOptions, setShowMoreOptions, scrollToBottom]);

    const handleExternalLinkOptionClick = useCallback((label) => () => {
        if (label === OptionLabelTypes.RESIDENT_OPTION_LABEL) {
            window.location.replace(residentRedirectUrl);
        }
    });

    const handleShowMoreClick = useCallback(() => {
        setDisplayAllOptions(true);
        scrollToBottom();
    }, [
        setDisplayAllOptions,
        scrollToBottom
    ]);

    const handleSubmitClick = useCallback(() => {
        sendFormattedMessage();
    }, [
        sendFormattedMessage
    ]);

    if (selected) {
        return null;
    }

    return (
        <div
            data-testid="message-options-component"
            className={cx(styles.component, {
                [styles.overlay]: isOverlay
            })}
        >
            {(layoutHeader || layoutSubHeader) && (
                <div className={styles.heading}>
                    {layoutHeader && (
                        <div
                            className={styles.header}
                            ref={optionsContainer}
                            tabIndex="0"
                        >
                            {layoutHeader}
                        </div>
                    )}
                    {layoutSubHeader && (
                        <div className={styles.subHeader}>
                            {layoutSubHeader}
                        </div>
                    )}
                </div>
            )}
            <div className={styles.list} data-testid="message-options">
                {displayedOptions.map(option => {
                    const isEnhancedOption = typeof option === 'object' && option !== null;
                    const isSelected = selectedOptions.indexOf(option) !== -1;
                    const variant = isSelected ? 'solid' : 'outline';
                    const label = !isEnhancedOption ? getOptionLabel(option) : getOptionLabel(option?.label);
                    const isExternalLink = label == OptionLabelTypes.RESIDENT_OPTION_LABEL;
                    let onClick;
                    if (isEnhancedOption) {
                        onClick = handleEnhancedOptionClick(option);
                    } else if (isExternalLink) {
                        onClick = handleExternalLinkOptionClick(label);
                    } else {
                        onClick = handleOptionClick(label);
                    }

                    return (
                        <Button
                            key={label ? label : option}
                            variant={variant}
                            className={styles.optionButton}
                            onClick={onClick}
                            enableDoubleClick={optionsMultiSelect}
                            aria-pressed={selectedOptions.indexOf(option) !== -1}
                            sessionId={sessionId}
                            funnelData={funnelData}
                        >
                            {label}
                        </Button>
                    );
                })}
                {displayMoreButtons && (
                    <Button
                        variant="outline"
                        className={styles.optionButton}
                        onClick={handleShowMoreClick}
                        enableDoubleClick={optionsMultiSelect}
                    >
                        {moreButtonLabel}
                    </Button>
                )}
            </div>
            {optionsMultiSelect && (
                <div className={styles.footer}>
                    <Button
                        fullWidth
                        disabled={selectedOptions.length === 0}
                        onClick={handleSubmitClick}
                        enableDoubleClick
                        className={styles.submit}
                    >
                        Submit
                    </Button>
                </div>
            )}
        </div>
    );
};

MessageOptions.propTypes = {
    payload: messagePayloadType,
    scrollToBottom: PropTypes.func,
    residentRedirectUrl: PropTypes.string
};

export default MessageOptions;
