import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import { InputAdornment } from '@material-ui/core';

import { isTest } from 'utils/env';
import { CHAT_MODE } from 'constants/Chat';
import { useChat } from 'components/Chat/ChatContext';
import SendIcon from 'components/Svg/SendSvg';
import TextField from 'components/FormField/TextField';
import styles from './ChatMessageForm.scss';

// Hack: set random input name to avoid browser to autocomplete input field
export const messageInputName = isTest ? 'message' : String(new Date().getTime());

const initialValues = {
    [messageInputName]: ''
};

function ChatMessageForm ({
    isEnabled,
    handleSubmit,
}) {
    const { awaitingResponse, chatMode } = useChat();
    const onSubmit = useCallback((values, { resetForm }) => {
        handleSubmit({
            message: values[messageInputName]
        });
        resetForm();
    }, [
        handleSubmit,
    ]);

    const formRendered = useCallback(({ submitForm, dirty }) => {
        return (
            <Form
                noValidate
                className={styles.form}
            >
                <TextField
                    marging="none"
                    multiline={false}
                    aria-label="Your message"
                    placeholder="Type a message..."
                    name={messageInputName}
                    height={40}
                    disabled={!isEnabled || (awaitingResponse && chatMode === CHAT_MODE.CHATBOT)}
                    endAdornment={(
                        <InputAdornment position="end" onClick={() => submitForm()}>
                            <SendIcon
                                role="button"
                                aria-label="Send message"
                                type="submit"
                                data-testid="chat-message-form-send-in"
                                className={cx(styles.sendIcon, {
                                    [styles.active]: dirty
                                })}
                            />
                        </InputAdornment>
                    )}
                />
            </Form>
        );
    }, [isEnabled, awaitingResponse, chatMode]);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {formRendered}
        </Formik>
    );
}

ChatMessageForm.propTypes = {
    isEnabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

export default ChatMessageForm;
