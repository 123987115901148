import React from 'react';
import PropTypes from 'prop-types';
import { useParentWindowCommunicator } from 'components/ParentWindowCommunicator/ParentWindowCommunicator';

function TourModalWrapper() {
    const { modalUrl, closeModal } = useParentWindowCommunicator();
    return (
        modalUrl && <TourModal url={modalUrl} onClose={closeModal} />
    );
}

export const TourModal = ({ url, onClose }) => {
    return (
        <div className="tourModal" data-testid="tour-modal">
            <div className="tourFrameContainer">
                <button className="closeButton" onClick={onClose} data-testid="close-button">×</button>
                <iframe className="tourFrame" src={url} data-testid="tour-iframe"></iframe>
            </div>
        </div>
    );
};

TourModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export default TourModalWrapper;
