import request from 'utils/request';

export const getChatbotConfiguration = (communityId, apiKey) => {
    return request(`retrieve_chuck_configuration?community_id=${communityId}&api_key=${apiKey}`)
        .then(([body]) => body);
};

export const getChatbotGreeting = (communityId, apiKey) => {
    return request(`chatbot-greeting`, {
        method: 'POST',
        data: {
            community_id: communityId,
            api_key: apiKey
        }
    })
    .then(([body]) => body);
};
