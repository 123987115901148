export const appendLinkToDocumentHead = (document, href) => {
    return new Promise(resolve => {
        const iframeCssLink = document.createElement('link');
        iframeCssLink.rel = 'stylesheet';
        iframeCssLink.href = href;
        iframeCssLink.onload = () => {
            resolve();
        };
        document.head.appendChild(iframeCssLink);
    });
};
